/** EXTERNALS **/


import { constantCase } from 'constant-case';


/** LOCALS **/


/** HELPERS **/


/** MAIN **/


export const constCase = function(string) {
    return constantCase(string);
};
