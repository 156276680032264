import {
    BulletList,
    Check,
    Checklist,
    Code,
    Embed,
    Font,
    FontH1,
    FontH2,
    FontH3,
    Info,
    Media,
    OrderedList,
    Quote,
    ShapesLine,
    Tip,
    Warning,
} from '@meisterlabs/svgs/Tint';

import { Star } from '@meisterlabs/svgs/Sections';
import { EmojiMindMeister } from '@meisterlabs/svgs/TintSmall';

import i18n from 'utils/i18n';
import TableOfContents from 'assets/icons/TableOfContents.svg';
import FontH4 from 'assets/icons/FontH4.svg';
import FontH5 from 'assets/icons/FontH5.svg';
import Notes from 'assets/icons/Notes.svg';

export const types = {
    unstyled: {
        id: 'unstyled',
        type: 'paragraph',
        name: i18n.t('blocks.unstyled'),
        description: i18n.t('block_descriptions.unstyled'),
        isNew: false,
        isConvertible: true,
        icon: Font,
        keywords: i18n.t('block_keywords.unstyled'),
    },
    header1: {
        id: 'header1',
        type: 'header',
        name: i18n.t('blocks.header1'),
        description: i18n.t('block_descriptions.header1'),
        isNew: false,
        isConvertible: true,
        icon: FontH1,
        blockProperties: {
            level: '1',
        },
        keywords: i18n.t('block_keywords.header1'),
    },
    header2: {
        id: 'header2',
        type: 'header',
        name: i18n.t('blocks.header2'),
        description: i18n.t('block_descriptions.header2'),
        isNew: false,
        isConvertible: true,
        icon: FontH2,
        blockProperties: {
            level: '2',
        },
        keywords: i18n.t('block_keywords.header2'),
    },
    header3: {
        id: 'header3',
        type: 'header',
        name: i18n.t('blocks.header3'),
        description: i18n.t('block_descriptions.header3'),
        isNew: false,
        isConvertible: true,
        icon: FontH3,
        blockProperties: {
            level: '3',
        },
        keywords: i18n.t('block_keywords.header3'),
    },
    header4: {
        id: 'header4',
        type: 'header',
        name: i18n.t('blocks.header4'),
        description: null, // Currently no description for Header4
        isNew: false,
        isConvertible: true,
        icon: FontH4,
        blockProperties: {
            level: '4',
        },
        keywords: i18n.t('block_keywords.header4'),
    },
    header5: {
        id: 'header5',
        type: 'header',
        name: i18n.t('blocks.header5'),
        description: null, // Currently no description for Header5
        isNew: false,
        isConvertible: true,
        icon: FontH5,
        blockProperties: {
            level: '5',
        },
        keywords: i18n.t('block_keywords.header5'),
    },
    unorderedList: {
        id: 'unordered-list',
        type: 'list-item',
        name: i18n.t('blocks.unordered-list'),
        description: i18n.t('block_descriptions.unordered-list'),
        isNew: false,
        isConvertible: true,
        icon: BulletList,
        blockProperties: {
            listType: 'unordered',
        },
        keywords: i18n.t('block_keywords.unordered-list'),
    },
    orderedList: {
        id: 'ordered-list',
        type: 'list-item',
        name: i18n.t('blocks.ordered-list'),
        description: i18n.t('block_descriptions.ordered-list'),
        isNew: false,
        isConvertible: true,
        icon: OrderedList,
        blockProperties: {
            listType: 'ordered',
        },
        keywords: i18n.t('block_keywords.ordered-list'),
    },
    checkList: {
        id: 'checklist',
        type: 'list-item',
        name: i18n.t('blocks.checklist'),
        description: i18n.t('block_descriptions.checklist'),
        isNew: false,
        isConvertible: true,
        icon: Checklist,
        blockProperties: {
            listType: 'check',
        },
        keywords: i18n.t('block_keywords.checklist'),
    },
    file: {
        id: 'file',
        type: 'file',
        name: i18n.t('blocks.file'),
        description: i18n.t('block_descriptions.file'),
        isNew: false,
        isConvertible: false,
        icon: Media,
        keywords: i18n.t('block_keywords.file'),
    },
    code: {
        id: 'code',
        type: 'code',
        name: i18n.t('blocks.code'),
        description: i18n.t('block_descriptions.code'),
        isNew: false,
        isConvertible: true,
        icon: Code,
        keywords: i18n.t('block_keywords.code'),
    },
    info: {
        id: 'info',
        type: 'icon',
        name: i18n.t('blocks.info'),
        description: i18n.t('block_descriptions.info'),
        isNew: false,
        isConvertible: true,
        icon: Info,
        blockProperties: {
            iconId: '7',
            iconType: 'meisternote',
            colorId: '7',
            colorType: 'meisternote',
        },
        keywords: i18n.t('block_keywords.info'),
    },
    warning: {
        id: 'warning',
        type: 'icon',
        name: i18n.t('blocks.warning'),
        description: i18n.t('block_descriptions.warning'),
        isNew: false,
        isConvertible: true,
        icon: Warning,
        blockProperties: {
            iconId: '9',
            iconType: 'meisternote',
            colorId: '2',
            colorType: 'meisternote',
        },
        keywords: i18n.t('block_keywords.warning'),
    },
    tip: {
        id: 'tip',
        type: 'icon',
        name: i18n.t('blocks.tip'),
        description: i18n.t('block_descriptions.tip'),
        isNew: false,
        isConvertible: true,
        icon: Tip,
        blockProperties: {
            iconId: '8',
            iconType: 'meisternote',
            colorId: '0',
            colorType: 'meisternote',
        },
        keywords: i18n.t('block_keywords.tip'),
    },
    quote: {
        id: 'quote',
        type: 'icon',
        name: i18n.t('blocks.quote'),
        description: i18n.t('block_descriptions.quote'),
        isNew: false,
        isConvertible: true,
        icon: Quote,
        blockProperties: {
            iconId: '10',
            iconType: 'meisternote',
            colorId: '8',
            colorType: 'meisternote',
        },
        keywords: i18n.t('block_keywords.quote'),
    },
    icon: {
        id: 'icon',
        type: 'icon',
        name: i18n.t('blocks.icon'),
        description: i18n.t('block_descriptions.icon'),
        isNew: false,
        isConvertible: true,
        icon: Star,
        blockProperties: {
            iconId: '2',
            iconType: 'meisternote',
            colorId: '0',
            colorType: 'meisternote',
        },
        keywords: i18n.t('block_keywords.icon'),
        isCurrentBlock: element => element.type === 'icon',
        actionAfterConversion: (_, blockKey) => {
            requestAnimationFrame(() => {
                const element = document
                    .querySelector(`[data-key="${blockKey}"].element-icon`)
                    ?.querySelector('button > div');

                element?.click();
            });
        },
    },
    mindmap: {
        id: 'mindmap',
        type: 'mindmeister',
        name: i18n.t('blocks.mindmap'),
        description: i18n.t('block_descriptions.mindmap'),
        isNew: false,
        isConvertible: false,
        icon: EmojiMindMeister,
        keywords: i18n.t('block_keywords.mindmap'),
        actionAfterConversion: (_, blockKey) => {
            requestAnimationFrame(() => {
                const element = document
                    .querySelector(`[data-key="${blockKey}"].element-mindmeister > div`);

                element?.click();
            });
        },
    },
    meistertask: {
        id: 'meistertask',
        type: 'meistertask',
        name: i18n.t('blocks.meistertask'),
        description: i18n.t('block_descriptions.meistertask'),
        isNew: false,
        isConvertible: false,
        icon: Check,
        keywords: i18n.t('block_keywords.meistertask'),
        actionAfterConversion: (_, blockKey) => {
            requestAnimationFrame(() => {
                const element = document
                    .querySelector(`[data-key="${blockKey}"].element-meistertask .react-popover-trigger`);

                element?.click();
            });
        },
    },
    horizontalRule: {
        id: 'horizontal-rule',
        type: 'horizontal-rule',
        name: i18n.t('blocks.horizontal-rule'),
        description: i18n.t('block_descriptions.horizontal-rule'),
        isNew: false,
        isConvertible: false,
        icon: ShapesLine,
        keywords: i18n.t('block_keywords.horizontal-rule'),
    },
    embed: {
        id: 'embed',
        type: 'embed',
        name: i18n.t('blocks.embed'),
        description: i18n.t('block_descriptions.embed'),
        isNew: false,
        isConvertible: false,
        icon: Embed,
        keywords: i18n.t('block_keywords.embed'),
        actionAfterConversion: (_, blockKey) => {
            requestAnimationFrame(() => {
                const element = document
                    .querySelector(`[data-key="${blockKey}"].element-embed`)
                    ?.querySelector('button > div');

                element?.click();
            });
        },
    },
    tableOfContents: {
        id: 'table-of-contents',
        type: 'toc',
        name: i18n.t('blocks.table-of-contents'),
        description: i18n.t('block_descriptions.table-of-contents'),
        isNew: false,
        isConvertible: false,
        icon: TableOfContents,
        keywords: i18n.t('block_keywords.table-of-contents'),
    },
    listOfPages: {
        id: 'list-of-pages',
        type: 'lop',
        name: i18n.t('blocks.list-of-pages'),
        description: i18n.t('block_descriptions.list-of-pages'),
        isNew: false,
        isConvertible: false,
        icon: Notes,
        keywords: i18n.t('block_keywords.list-of-pages'),
    },
};

export const blocks = [
    types.unstyled,
    types.checkList,
    types.header1,
    types.header2,
    types.header3,
    types.header4,
    types.header5,
    types.tableOfContents,
    types.listOfPages,
    types.unorderedList,
    types.orderedList,
    types.file,
    types.code,
    types.info,
    types.warning,
    types.tip,
    types.quote,
    types.icon,
    types.horizontalRule,
    types.embed,
    types.mindmap,
    types.meistertask,
];

export const adderItems = [
    types.unstyled,
    types.checkList,
    types.header1,
    types.header2,
    types.header3,
    {
        id: 'more',
        name: i18n.t('generic.more'),
    },
    types.tableOfContents,
    types.listOfPages,
    types.unorderedList,
    types.orderedList,
    types.file,
    types.code,
    types.info,
    types.warning,
    types.tip,
    types.quote,
    types.icon,
    types.horizontalRule,
    types.embed,
    {
        id: 'integrations',
        name: i18n.t('generic.integrations'),
    },
    types.mindmap,
    types.meistertask,
];
