/** EXTERNALS **/


import React from 'react';


/** LOCALS **/


import ICONS from 'src/config/icons';
import NoteIcon from './Icon';


/** HELPERS **/


/** MAIN **/


export const PublicNoteIcon = function({ json }) {
    if (!json.icon) return null;

    const icon = ICONS.getIconData(json.icon); // Use Default Fallback Icon if not found

    return (
        <NoteIcon
            hasNoteCover={!!json.cover}
            noteIcon={icon}
        />
    );
};
