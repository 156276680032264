/** EXTERNALS **/


import { Obj } from '@meisterlabs/common';
import { colors as uiColors } from '@meisterlabs/ui';
import { sectionIcons } from '@meisterlabs/svg-helpers';
import {
    Archive,
    Edit,
    Favorites,
    File,
    Info,
    Quote,
    Statistics,
    Tasks,
    Tip,
    User,
    Warning,
} from '@meisterlabs/svgs/Tint';


/** LOCALS **/


/** HELPERS **/


/** MAIN **/


const iconTypes = {
    meisternote: 'meisternote',
    sectionIcon: 'section_icon',
};

const colorTypes = {
    meisternote: 'meisternote',
};

const icons = [
    { id: 2, type: iconTypes.meisternote, component: Favorites },
    { id: 7, type: iconTypes.meisternote, component: Info },
    { id: 8, type: iconTypes.meisternote, component: Tip },
    { id: 9, type: iconTypes.meisternote, component: Warning },
    { id: 10, type: iconTypes.meisternote, component: Quote },
    { id: 0, type: iconTypes.meisternote, component: File },
    { id: 1, type: iconTypes.meisternote, component: Tasks },
    { id: 3, type: iconTypes.meisternote, component: User },
    { id: 4, type: iconTypes.meisternote, component: Edit },
    { id: 5, type: iconTypes.meisternote, component: Statistics },
    { id: 6, type: iconTypes.meisternote, component: Archive },
    ...sectionIcons.ids
        .map(id => parseInt(id))
        .map(function(id) {
            return {
                id,
                type: iconTypes.sectionIcon,
                component: sectionIcons.iconById(id),
            };
        }),
];

const defaultOpacity = 0.15;

const colors = [
    { id: 0, type: colorTypes.meisternote, value: uiColors.black, opacity: 0.05 },
    { id: 1, type: colorTypes.meisternote, value: uiColors.red, opacity: defaultOpacity },
    { id: 2, type: colorTypes.meisternote, value: uiColors.orange, opacity: defaultOpacity },
    { id: 3, type: colorTypes.meisternote, value: uiColors.yellow, opacity: defaultOpacity },
    { id: 4, type: colorTypes.meisternote, value: uiColors.grass, opacity: defaultOpacity },
    { id: 5, type: colorTypes.meisternote, value: uiColors.green, opacity: defaultOpacity },
    { id: 6, type: colorTypes.meisternote, value: uiColors.turquoise, opacity: defaultOpacity },
    { id: 7, type: colorTypes.meisternote, value: uiColors.blue, opacity: defaultOpacity },
    { id: 8, type: colorTypes.meisternote, value: uiColors.navy, opacity: defaultOpacity },
    { id: 9, type: colorTypes.meisternote, value: uiColors.purple, opacity: defaultOpacity },
    { id: 10, type: colorTypes.meisternote, value: uiColors.pink, opacity: defaultOpacity },
];

const presets = {
    default: {
        icon: { type: iconTypes.meisternote, id: 2 },
        color: { type: colorTypes.meisternote, id: 0 },
    },
    info: {
        icon: { type: iconTypes.meisternote, id: 7 },
        color: { type: colorTypes.meisternote, id: 7 },
    },
    warning: {
        icon: { type: iconTypes.meisternote, id: 9 },
        color: { type: colorTypes.meisternote, id: 2 },
    },
    tip: {
        icon: { type: iconTypes.meisternote, id: 8 },
        color: { type: colorTypes.meisternote, id: 0 },
    },
    quote: {
        icon: { type: iconTypes.meisternote, id: 10 },
        color: { type: colorTypes.meisternote, id: 8 },
    },
};

const findColorBy = function(obj) {
    return colors.find(function(color) {
        return Obj
            .of(color)
            .valuesEqual(obj);
    });
};

const findIconBy = function(obj) {
    return icons.find(function(icon) {
        return Obj
            .of(icon)
            .valuesEqual(obj);
    });
};

export default {
    iconTypes,
    colorTypes,
    icons,
    colors,
    presets,
    findColorBy,
    findIconBy,
};
