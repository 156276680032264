/** EXTERNALS **/


import pipe from '@meisterlabs/pipe';


/** LOCALS **/


import { lowerCase } from './lowerCase';
import { pascalCase } from './pascalCase';
import { endsWith } from './endsWith';


/** HELPERS **/


/** MAIN **/


export const typeCase = function(string) {
    return pipe(string, [
        lowerCase,
        pascalCase,
        string => endsWith(string, 'T') ? string : `${string}T`,
    ]);
};
