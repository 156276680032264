/** EXTERNALS **/


import React from 'react';
import { HoverView, View } from '@meisterlabs/knightrider';


/** LOCALS **/


import CoverAttribution from './CoverAttribution';
import { coverHeight } from 'config/sizes';
import ZINDEX from 'config/z-index';


/** HELPERS **/


export const getStyle = function({ offset, coverValue }) {
    return {
        root: {
            width: '100%',
            maxHeight: coverHeight,
            height: coverHeight,
        },
        wrapper: {
            width: '100%',
            height: '100%',
            position: 'relative',
        },
        image: {
            root: {
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                position: 'relative',
                zIndex: ZINDEX.noteCoverImage,
            },
            background: {
                width: '100%',
                height: '100%',
                background: coverValue,
                transform: offset,
            },
        },
    };
};


/** MAIN **/


class Cover extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            offset: 0,
        };
    }

    componentDidMount() {
        if (this.props.onCover) {
            this.props.onCover(this); // We do not use this in Public notes
        }
    }

    setOffset(offset) {
        this.setState({ offset });
    }

    render() {
        const {
            attribution,
            coverValue,
            isMobile = false,
        } = this.props;

        const offset = this.state.offset;
        const style = getStyle({ coverValue, offset });
        const showAttribution = !isMobile && attribution;

        return (
            <HoverView style={style.root}>
                {function(isHovered) {
                    return (
                        <View style={style.wrapper}>
                            <View style={style.image.root}>
                                <View style={style.image.background} />
                            </View>
                            {showAttribution && (
                                <CoverAttribution
                                    data={attribution}
                                    style={style.attribution}
                                    isCoverHovered={isHovered}
                                />
                            )}
                        </View>
                    );
                }}
            </HoverView>
        );
    }
}

export default Cover;
