import React from 'react';
import { Icon, Text, View } from '@meisterlabs/knightrider';
import { colors, fonts, rgba } from '@meisterlabs/ui';
import { Icon as MeisterTaskIcon } from '@meisterlabs/svgs/MeisterTask';
import { Icon as MindMeisterIcon } from '@meisterlabs/svgs/MindMeister';
import { Lock } from '@meisterlabs/svgs/TintSmall';
import { Hyperlink } from '@meisterlabs/react-links';

import i18n from 'utils/i18n';
import parseI18nHtml from 'src/utils/parseI18nHtml';

// This list contains all the block types that are allowed to be rendered as Placeholders
// when no specific renderer is found.
// We only want to warn about missing renderers for other Block types that we do not fallback intentionally.
const allowedDefaults = ['mindmeister', 'meistertask', 'file'];

const baseStyle = {
    root: {
        padding: '16px 12px',
        borderRadius: 12,
        backgroundColor: colors.grey200,
        height: 130,
        boxShadow: '0px 3px 4px -2px rgba(0, 0, 0, 0.07), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.07)',
        border: `1px solid ${rgba.black(0.05)}`,
    },
    contentWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
    },
    textWrapper: {
        flexDirection: 'column',
        paddingLeft: 12,
    },
    icon: {
        height: 38,
        with: 38,
    },
    title: {
        ...fonts.m.bold,
    },
    cta: {
        ...fonts.m.medium,
    },
    links: {
        textDecoration: 'underline',
        color: colors.grey700,
    },
};

const typeStyles = {
    mindmeister: {
        ...baseStyle,
        root: {
            ...baseStyle.root,
            backgroundColor: rgba.pink(0.05),
            height: 300,
        },
    },
    meistertask: {
        ...baseStyle,
        root: {
            ...baseStyle.root,
            backgroundColor: rgba.blue(0.05),
            height: 'auto',
        },
    },
};

const typeContents = {
    mindmeister: {
        Icon: <MindMeisterIcon />,
        title: i18n.t('public_notes.public.placeholders.mindmeister'),
        cta: i18n.t('public_notes.public.placeholders.cta'),
    },
    meistertask: {
        Icon: <MeisterTaskIcon />,
        title: i18n.t('public_notes.public.placeholders.meistertask'),
        cta: i18n.t('public_notes.public.placeholders.cta'),
    },
    default: {
        Icon: <Lock />,
        title: i18n.t('public_notes.public.placeholders.default'),
        cta: i18n.t('public_notes.public.placeholders.cta'),
    },
};

const parseWithLinks = function(text) {
    return parseI18nHtml(text)
        .map(function({ type, value }, index) {
            switch (type) {
            case 'LoginLink':
                return (
                    <Hyperlink
                        key={index}
                        blank
                        url={CONFIG.meisternote.loginUrl}
                        style={baseStyle.links}
                    >
                        {value}
                    </Hyperlink>
                );
            case 'SignupLink':
                return (
                    <Hyperlink
                        key={index}
                        blank
                        url={CONFIG.meisternote.signupUrl}
                        style={baseStyle.links}
                    >
                        {value}
                    </Hyperlink>
                );
            default:
                return value;
            }
        });
};

export default function({ element, attributes }) {
    if (!PRODUCTION && !allowedDefaults.includes(element.type)) {
        // eslint-disable-next-line no-console
        console.warn(`No renderer found for block type ${element.type}. Hiding content.`);
    }

    const style = typeStyles[element.type] || baseStyle;
    const content = typeContents[element.type] || typeContents.default;

    return (
        <div contentEditable={false} {...attributes} style={{ userSelect: 'none' }}>
            <View style={style.root}>
                <View style={style.contentWrapper}>
                    <Icon style={style.icon}>
                        {content.Icon}
                    </Icon>
                    <View style={style.textWrapper}>
                        <Text style={style.title}>
                            {content.title}
                        </Text>
                        <Text>
                            {parseWithLinks(content.cta)}
                        </Text>
                    </View>
                </View>
            </View>
        </div>
    );
}
