/** EXTERNALS **/


import React from 'react';


/** LOCALS **/


import buildAttribution from '../utils/buildAttribution';
import Cover from './Cover';
import { getCoverValueFromUrl, mnImageUrl, solidColors, unsplashImageUrl } from 'config/covers';


/** HELPERS **/


const generateCoverValue = function({ cover, noteToken }) {
    if (cover.type === 'solid') {
        return solidColors[cover.id];
    }

    if (cover.type === 'mn_image') {
        return getCoverValueFromUrl(mnImageUrl(cover.id));
    }

    if (cover.type === 'unsplash') {
        return getCoverValueFromUrl(unsplashImageUrl(cover.data));
    }

    // Previewer
    if (cover.url) {
        return getCoverValueFromUrl(cover.url);
    }

    // PublicNote
    if (cover.type === 'custom_image') {
        // Support for local dev environment and odes by allowing to define baseURL
        const contentBaseUrl = CONFIG.meisternote.livePages.contentUrl || '';
        const coverUrl = `${contentBaseUrl}/notes/${noteToken}/cover/original${cover.meta.ext}`;

        return getCoverValueFromUrl(coverUrl);
    }

    return null;
};

const generateAttribution = function(cover) {
    if (cover.type === 'unsplash') {
        const {
            author,
            authorUrl,
            imageLink,
        } = cover.data;

        return buildAttribution(author, authorUrl, imageLink);
    }

    return null;
};


/** MAIN **/


const PublicNoteCover = function({ json }) {
    if (!json.cover) return null;

    const { cover, noteToken } = json;

    const coverValue = generateCoverValue({ cover, noteToken });
    const attribution = generateAttribution(json.cover);

    if (!coverValue) return null;

    return (
        <Cover
            coverValue={coverValue}
            attribution={attribution}
        />
    );
};

export default PublicNoteCover;
