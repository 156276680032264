/** EXTERNALS **/


import Redux from '@meisterlabs/redux';


/** LOCALS **/


import fromFn from './fromFn';


/** HELPERS **/


const cache = new Map();


const getSubId = id => `fromStore.${id}`;
const genCallback = (stream, id) => () => stream(Redux.getStore(id));


const subscribe = function(stream, id) {
    Redux.subscribe({
        id: getSubId(id),
        store: id,
        callback: genCallback(stream, id),
    });
};


// Hot reloading removes all stores and re-adds the previous state through mergeStores
// We push the new stores into the streams here and re-add the subscriptions if necessary
Redux.after('mergeStores', function(newStores, output) {
    cache.forEach(function(Store$, id) {
        Store$(newStores[id]);

        // hot reloading removes all subscriptions, re-add those for store streams here
        const gotUnsubscribed = !Redux.subscriptionIds().includes(getSubId(id));

        if (gotUnsubscribed) subscribe(Store$, id);
    });

    return output;
});


/** MAIN **/


export default function(id) {
    if (cache.has(id)) return cache.get(id);

    const Store$ = fromFn(function(stream) {
        const callback = genCallback(stream, id);

        if (stream() == null) {
            const onStoreRegistered = function(input, output) {
                if (input.id === id) callback();
                return output;
            };

            Redux.after('registerStore', onStoreRegistered);
            Redux.after('registerComputedStore', onStoreRegistered);
        }

        subscribe(stream, id);
        callback();
    });

    cache.set(id, Store$);

    return Store$;
}
