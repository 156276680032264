/** EXTERNALS **/


import React from 'react';
import { fonts, I18nHtml } from '@meisterlabs/ui';
import { Hyperlink } from '@meisterlabs/react-links';
import { Text, View } from '@meisterlabs/knightrider';


/** LOCALS **/


/** HELPERS **/


const getStyle = function({ isCoverHovered }) {
    return {
        root: {
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            opacity: isCoverHovered ? 1 : 0,
            transition: 'opacity 200ms',
            zIndex: 0,
        },
        overlay: {
            width: '100%',
            height: '100%',
            background: 'linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%)',
        },
        wrapper: {
            position: 'absolute',
            bottom: 10,
            right: 15,
            maxWidth: '100%',
        },
        text: {
            ...fonts.m.regular,
            color: 'rgba(255, 255, 255, 0.7)',
            whiteSpace: 'pre',
            cursor: 'default',
        },
        link: {
            ...fonts.m.regular,
            color: 'rgba(255, 255, 255, 0.7)',
            whiteSpace: 'pre',
            textDecoration: 'underline',
        },
    };
};


/** MAIN **/


const CoverAttribution = function({ isCoverHovered, data }) {
    const style = getStyle({ isCoverHovered });

    return (
        <View style={style.root}>
            <View style={style.overlay} />
            <I18nHtml
                tokens={data}
                render={function({ children }) {
                    return (
                        <View style={style.wrapper}>
                            {children}
                        </View>
                    );
                }}
                tokenRenderers={{
                    Text({ key, token }) {
                        return (
                            <Text
                                key={key}
                                style={style.text}
                            >
                                {token.value}
                            </Text>
                        );
                    },
                    Link({ key, token }) {
                        return (
                            <Hyperlink
                                blank
                                key={key}
                                url={token.url}
                                style={style.link}
                            >
                                {token.text}
                            </Hyperlink>
                        );
                    },
                }}
            />
        </View>
    );
};

export default CoverAttribution;
