/** EXTERNALS **/


import { useRef } from 'react';


/** LOCALS **/


/** HELPERS **/


const defaultShouldKeep = value => value != null;


/** MAIN **/


export const useFrozenValue = function(value, shouldKeep = defaultShouldKeep) {
    const ref = useRef(value);

    if (ref.current !== value && shouldKeep(value, ref.current)) {
        ref.current = value;
    }

    return ref.current;
};
