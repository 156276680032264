/** EXTERNALS **/


/** LOCALS **/


/** HELPERS **/


const duration = (end, start) => `${(end - start).toFixed(2)}ms`;


/** MAIN **/


const pipeDebug = function(value, fns, options) {
    const data = {
        start: performance.now(),
        input: value,
        steps: new Array(fns.length),
    };

    for (let i = 0; i < fns.length; i++) {
        const step = { start: performance.now() };

        value = fns[i](value);
        step.end = performance.now();
        step.value = value;
        data.steps[i] = step;
    }

    data.end = performance.now();

    const name = options.name
        ? `pipe - ${options.name} - ${duration(data.end, data.start)}`
        : `pipe - ${duration(data.end, data.start)}`;

    // eslint-disable-next-line no-console
    console.groupCollapsed(name);
    // eslint-disable-next-line no-console
    console.log('Input', value);

    data.steps.forEach(function({ start, end, value }, idx) {
        const groupName = `Step ${idx} - ${duration(end, start)}`;

        // eslint-disable-next-line no-console
        console.groupCollapsed(groupName);
        // eslint-disable-next-line no-console
        console.log('Output', value);
        // eslint-disable-next-line no-console
        console.groupEnd(groupName);
    });

    // eslint-disable-next-line no-console
    console.groupEnd(name);

    return value;
};

// TODO stacktrace?
const pipe = function(value, fns, options) {
    if (options?.debug) {
        return pipeDebug(value, fns, options);
    }

    for (let i = 0; i < fns.length; i++) {
        value = fns[i](value);
    }

    return value;
};

export default pipe;
