/* eslint global-require: 0 import/no-webpack-loader-syntax: 0 */
/** EXTERNALS **/


import i18n from 'i18n-js';

import {
    allPass,
    anyPass,
    Arr,
    Maybe,
    Obj,
    Switch,
    Try,
} from '@meisterlabs/common';


/** LOCALS **/


import { updateLocale } from 'utils/moment';

const datasets = {
    da: require('assets/i18n/da.yml'),
    de: require('assets/i18n/de.yml'),
    en: require('assets/i18n/en.yml'),
    es: require('assets/i18n/es.yml'),
    fr: require('assets/i18n/fr.yml'),
    it: require('assets/i18n/it.yml'),
    ja: require('assets/i18n/ja.yml'),
    ko: require('assets/i18n/ko.yml'),
    nl: require('assets/i18n/nl.yml'),
    pt: require('assets/i18n/pt.yml'),
    ru: require('assets/i18n/ru.yml'),
    zh: require('assets/i18n/zh-cn.yml'),
};


/** HELPERS **/


const defaultLocale = 'en';

const availableLocales = Obj
    .of(datasets)
    .keys();

const userLocales = Arr
    .of(navigator.languages || [])
    .merge([
        navigator.language,
        navigator.userLanguage,
        navigator.systemLanguage,
        navigator.browserLanguage,
    ])
    .get();

const localStorageLocale = Try
    .of(() => JSON.parse(localStorage.getItem('_ml_locale')))
    .filter(item => !!item)
    .map(item => item.value)
    .getOrElse(null);

const cookieLocale = Maybe
    .of(document.cookie)
    .orElse(() => Maybe.of(''))
    .toStr()
    .split(/;\s*/)
    .mapEach(cookie => cookie.split('='))
    .find(pair => pair[0] === 'locale')
    .toMaybe()
    .map(pair => pair[1])
    .getOrElse(null);

const possibleLocales = Arr
    .of([
        cookieLocale,
        localStorageLocale,
    ])
    .merge(userLocales)
    .filter(value => typeof value === 'string')
    .mapEach(locale => locale.split('-')[0]) // en-US -> en
    .mapEach(locale => locale.toLowerCase())
    .filter(locale => availableLocales.has(locale))
    .uniq();

const locale = possibleLocales
    .head()
    .toMaybe()
    .getOrElse(defaultLocale);


/** MAIN **/


// We also want to update moment's locale
updateLocale({ locale });

// Used by mopinion
window[CONFIG.mopinion.metaKeys.locale] = locale;

i18n.defaultLocale = defaultLocale;
i18n.locale = locale;
i18n.translations = {};

i18n.load = function(callback) {
    datasets[i18n.locale](function(dataset) {
        Object.assign(i18n.translations, dataset);
        callback();
    });
};


i18n.item_scope = function(item) {
    return [
        'items',
        i18n.locale === 'ru' ? 'case4' : null,
        item,
    ]
        .filter(x => !!x)
        .join('.');
};


// Russian pluralization is more a little bit more complex:
// 1. Where count is zero, word has one ending
// 2. Where count is 1, 21, 31, 41, …: word has another ending
// 3. Where count is 2–4, 22–24, 32–34, …: word has yet another ending
// 4. Where count is 5–20, 25–30, 35–40, …: word has yet another ending
i18n.pluralization.ru = function(count) {
    return Switch
        .of({})
        .case(
            () => count === 0,
            () => 'zero',
        )
        .case(
            allPass([
                () => (count % 10) === 1,
                () => count % 100 !== 11,
            ]),
            () => 'one',
        )
        .case(
            allPass([
                () => [2, 3, 4].includes(count % 10),
                () => ![12, 13, 14].includes(count % 100),
            ]),
            () => 'few',
        )
        .case(
            anyPass([
                () => (count % 10) === 0,
                () => [5, 6, 7, 8, 9].includes(count % 10),
                () => [11, 12, 13, 14].includes(count % 100),
            ]),
            () => 'many',
        )
        .default(() => 'other')
        .fold(key => [key]);
};

i18n.pluralization.zh = () => ['other'];
i18n.pluralization.ko = () => ['other'];
i18n.pluralization.ja = () => ['other'];


// Use for hardcoded colons, which do not fit into translations
export const colonForLocale = function(locale) {
    return Switch
        .of(locale)
        .case(
            locale => locale === 'fr',
            () => ' :'
        )
        .default(
            () => ':'
        )
        .get();
};

export const wrapInQuotationMarks = function(text, locale) {
    return Switch
        .of(locale)
        .case(
            locale => locale === 'de',
            () => `„${text}“`
        )
        .default(
            () => `“${text}”`
        )
        .get();
};

export default i18n;
