/** EXTERNALS **/


import React from 'react';
import { Text, View } from '@meisterlabs/knightrider';
import { Hyperlink } from '@meisterlabs/react-links';
import { Button, colors, fonts } from '@meisterlabs/ui';


/** LOCALS **/


import i18n from 'utils/i18n';


/** HELPERS **/


const style = {
    wrapper: {
        alignItems: 'center',
        gap: 24,
    },
    link: {
        color: colors.blue,
        ...fonts.m.medium,
    },
    signUpButton: {
        color: colors.white,
        ...fonts.m.medium,
    },
};


/** MAIN **/


const PublicButtons = function() {
    return (
        <View style={style.wrapper}>
            <Hyperlink
                style={style.link}
                url={CONFIG.meisternote.loginUrl} /* what URL should we use for this? */
                blank
            >
                {i18n.t('generic.login')}
            </Hyperlink>
            <Hyperlink
                url={CONFIG.meisternote.accountUrl}
                blank
            >
                <Button>
                    <Text style={style.signUpButton}>
                        {i18n.t('generic.get_started')}
                    </Text>
                </Button>
            </Hyperlink>
        </View>
    );
};

export default PublicButtons;
