/** EXTERNALS **/


import React from 'react';
import CookieConsent from '@meisterlabs/cookie-consent/dist/cookie-consent-standalone';


/** LOCALS **/


import i18n from 'utils/i18n';
import PublicWrapper from 'features/PublicNotes/wrappers/PublicWrapper';
import '../shared/styles/ml-prism.css';
import '../shared/styles/prism.css';


/** MAIN **/


export default function() {
    // By using local mode we rely on the browsers cookies to store the consent
    CookieConsent({
        localMode: true,
        locale: i18n.locale,
        baseUrl: CONFIG.cookieConsent.baseUrl,
        baseMMUrl: CONFIG.cookieConsent.baseMMUrl,
    });

    return (
        <PublicWrapper />
    );
}
