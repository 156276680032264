/** EXTERNALS **/


import React from 'react';


/** LOCALS **/


import NoteIcon from './Icon';
import { useStore } from 'src/utils/Stream';


/** HELPERS **/


/** MAIN **/


export const MeisterNoteIcon = function({ noteToken }) {
    const Notes = useStore('Notes');
    const NoteSettings = useStore('NoteSettings');
    const Covers = useStore('Covers');

    const noteId = Notes.findIdForToken(noteToken);
    const noteIcon = NoteSettings.getIconData(noteId);

    const hasNoteCover = NoteSettings.getCover({
        Notes,
        Covers,
        noteToken,
    }).exists;

    return (
        <NoteIcon
            hasNoteCover={hasNoteCover}
            noteIcon={noteIcon}
        />
    );
};
