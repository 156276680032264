/** EXTERNALS **/


import flyd from 'flyd';


/** LOCALS **/


import uniq from './uniq';
import status from './status';


/** HELPERS **/


/** MAIN **/


const streamOf = function(stream) {
    const Interface = function(...params) {
        return stream(...params);
    };

    Object.assign(Interface, {
        stream,

        // get() {
        //     return stream();
        // },
        //
        // set(value) {
        //     stream(value);
        //     return Interface;
        // },

        chain(fn) {
            return fn(this);
        },

        end() {
            stream.end(true);

            return Interface;
        },

        subscribe(fn) {
            const newSteam = flyd.on(function(state) {
                fn(state);
            }, stream);

            const unsubscribe = () => {
                newSteam.end(true);

                return null;
            };

            return unsubscribe;
        },

        onChange(fn) {
            let didInit = false;

            const newSteam = flyd.on(function(state) {
                if (!didInit) {
                    didInit = true;

                    return;
                }

                fn(state);
            }, stream);

            const unsubscribe = () => {
                newSteam.end(true);

                return null;
            };

            return unsubscribe;
        },

        log() {
            // eslint-disable-next-line no-console
            Interface.subscribe(state => console.log(state));

            return Interface;
        },

        pipe(fn) {
            const newStream = flyd.combine(function(state, self, changed) {
                fn(state(), uniq(self), changed);
            }, [stream]);

            return streamOf(newStream);
        },

        pipeReady(fn) {
            return Interface.pipe(function(value, self, changed) {
                if (!status.isReady(value)) return self(value);

                fn(value, self, changed);
            });
        },

        pipePending(fn) {
            return Interface.pipe(function(value, self, changed) {
                if (!status.isPending(value)) return self(value);

                fn(value, self, changed);
            });
        },

        pipeError(fn) {
            return Interface.pipe(function(value, self, changed) {
                if (!status.isError(value)) return self(value);

                fn(value, self, changed);
            });
        },

        pipeAbort(fn) {
            return Interface.pipe(function(value, self, changed) {
                if (!status.isAbort(value)) return self(value);

                fn(value, self, changed);
            });
        },

        debounce(duration, fn) {
            let timeout;

            return Interface.pipe(function(value, self, changed) {
                if (timeout) clearTimeout(timeout);

                timeout = setTimeout(function() {
                    fn(value, self, changed);
                }, duration);
            });
        },

        forward(stream) {
            return Interface.pipe(value => stream(value));
        },

        tap(fn) {
            Interface.subscribe(fn);

            return Interface;
        },
    });

    return Interface;
};

export default streamOf;
