/** EXTERNALS **/


import Redux from '@meisterlabs/redux';
import { unstable_batchedUpdates } from 'react-dom';
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/shim/with-selector';

import { useMemo } from '@meisterlabs/react-hooks';


/** LOCALS **/


/** HELPERS **/


const queue = [];
let tick;

const flushQueue = function() {
    unstable_batchedUpdates(() => {
        let next;
        // eslint-disable-next-line
        while (next = queue.shift()) {
            next();
        }
    });
};

Redux.before('flushActions', function(input, flushActions) {
    let result;

    unstable_batchedUpdates(() => {
        result = flushActions(input);
    });

    return result;
});


/** MAIN **/


export default function(stream, isEqual) {
    const [subscribe, getSnapshot] = useMemo(function() {
        const subscribe = function(callback) {
            const unsubscribe = stream.onChange(function() {
                queue.push(callback);

                if (Redux.isFlushingActions()) {
                    flushQueue();

                    return;
                }

                if (tick) return;

                tick = queueMicrotask(() => {
                    tick = null;

                    flushQueue();
                });
            });

            return function() {
                const index = queue.findIndex(fn => fn === callback);

                if (index >= 0) queue.splice(index, 1);

                unsubscribe();
            };
        };

        const getSnapshot = function() {
            return stream();
        };

        return [subscribe, getSnapshot];
    }, [stream]);

    return useSyncExternalStoreWithSelector(
        subscribe,
        getSnapshot,
        () => null,
        snapshot => snapshot,
        isEqual,
    );
}
