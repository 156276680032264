/** EXTERNALS **/


import { useCallback, useState } from '@meisterlabs/react-hooks';


/** LOCALS **/


/** HELPERS **/


/** MAIN **/


const useScroll = function() {
    const [isScrolled, setState] = useState(false);

    const onScroll = useCallback(function({ ScrollView }) {
        const isScrolledNow = ScrollView.getScrollTop() > 0;

        if (isScrolled === isScrolledNow) return;

        setState(isScrolledNow);
    }, [isScrolled, setState]);

    return { isScrolled, onScroll };
};

export { useScroll };
