/** EXTERNALS **/


import React from 'react';
import { withElementRenderer } from '@meisterlabs/editor';


/** LOCALS **/


import Placeholders from '../components/PublicNotes/Placeholders';


/** HELPERS **/


/** MAIN **/


// Default Renderer for blocks with no specific renderer
export const withDefault = function() {
    withElementRenderer(
        () => true, // Always render this component if no other renderer matches -> needs to be the last renderer
        props => <Placeholders {...props} />,
    );
};
