/** EXTERNALS **/


/** LOCALS **/


import CompositeError from './CompositeError';


/** HELPERS **/


/** MAIN **/


export default class GraphQLError {
    constructor(errors, state) {
        /*
            [{
                message: String,
                locations: [{ line: Int, column: Int }],
                path: [String | Int]?,
                code: Integer?,
            }]
        */
        this.errors = errors;

        // Stream state
        this.state = state;
    }

    concat(error) {
        return new CompositeError([this, error]);
    }

    messages() {
        return this.errors.map(error => error.message);
    }

    statusCodes() {
        return this.errors
            .map(error => error.code)
            .filter(statusCode => !isNaN(statusCode));
    }

    types() {
        return ['GraphQLError'];
    }

    log() {
        // eslint-disable-next-line
        console.log(this.state);

        const message = JSON.stringify(this.errors, 0, 4);
        // eslint-disable-next-line
        console.error(message);
    }
}
