// Slate imports
import { createEditable, withClipboard, withReadOnly } from '@meisterlabs/editor';
import '@meisterlabs/editor/dist/style.css';
// END Slate imports

import { withRendering } from '../utils/withRendering';
import { scrollToBlock as scrollToBlockDefault } from 'src/utils/Editor/scrollToBlock';


export const useEditable = function({ noteToken, noteId }) {
    const scrollToBlock = ({ key }) => {
        scrollToBlockDefault({ blockKey: key, parentSelector: '.public-page-root' });
    };

    return createEditable(function() {
        withReadOnly(true);

        withRendering({
            noteId,
            noteToken,
            scrollToBlock,
        });

        withClipboard();
    });

};
