/** EXTERNALS **/


import Redux from '@meisterlabs/redux';
import { useCallback } from 'react';


/** LOCALS **/


/** HELPERS **/


/** MAIN **/


export default function useAction(id, context, tracking) {
    return useCallback((payload = {}) => {
        if (DEVELOPMENT && !Redux.getAction(id)) throw new Error(`Action not found: ${id}`);

        Redux.dispatch(id, payload, context, [{ context: {}, ...tracking }]);
    }, [id]);
}
