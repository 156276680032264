/** EXTERNALS **/


import React, { useContext } from 'react';
import { View } from '@meisterlabs/knightrider';


/** LOCALS **/


import 'features/Editor/components/style.css';
import { useEditor } from '../../hooks/useEditor';
import { useEditable } from '../../hooks/useEditable';
import { JsonContext } from '../../utils/context';
import { EditorWrapper } from 'src/features/Editor/components/EditorWrapper';


/** HELPERS **/


const style = {
    wrapper: {
        padding: 0,
    },
};


/** MAIN **/


const Component = function() {
    const { noteId, noteToken, yjsContent } = useContext(JsonContext);
    const editor = useEditor({ content: yjsContent });
    const Editable = useEditable({ noteId, noteToken });

    return (
        <EditorWrapper>
            <View style={style.wrapper}>
                <Editable editor={editor} />
            </View>
        </EditorWrapper>
    );
};

export default Component;
