/** EXTERNALS **/


import flyd from 'flyd';


/** LOCALS **/


import streamOf from './of';
import uniq from './uniq';


/** HELPERS **/


/** MAIN **/


const fromFn = function(fn) {
    const stream = flyd.stream();

    fn(uniq(stream));

    return streamOf(stream);
};

export default fromFn;
