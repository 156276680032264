/** EXTERNALS **/


import Redux from '@meisterlabs/redux';
import { colors, rgba } from '@meisterlabs/ui';


/** LOCALS **/


import { headerHeight } from 'config/sizes';
import ZINDEX from 'config/z-index';
import ANIMATIONS from 'config/animations';


/** HELPERS **/


/** MAIN **/


export const RAISED_BOX_SHADOW = [
    { v: 0.5, blur: 3, spread: -1, color: rgba.black(0.2) },
    { v: 5, blur: 30, spread: -10, color: rgba.black(0.15) },
];

export const RAISED_BOX_SHADOW_TRANSITION = {
    property: 'box-shadow',
    duration: '200ms',
    easing: 'ease-in-out',
};

export default Redux.Style

    .mergeStyles({
        root: Redux.Rules
            .height(headerHeight)
            .userSelect('none')
            .position('absolute')
            .alignItems('center')
            // Allow cover to receive hover events.
            .pointerEvents('none')
            .top(0)
            .left(0)
            .right(0)
            .zIndex(ZINDEX.noteHeader)
            .paddingLeft(16)
            .paddingRight(16),
        stretch: Redux.Rules.stretch(),
        headerGroup: Redux.Rules
            .height(44)
            .borderRadius(22)
            .paddingHorizontal(6)
            .marginLeft(16)
            .alignItems('center')
            .pointerEvents('all')
            .backgroundColor(R => R.option('isRaised') ? colors.white : 'none')
            .boxShadow(R => R.option('isRaised') ? RAISED_BOX_SHADOW : [])
            .transition(RAISED_BOX_SHADOW_TRANSITION),
        // not sure why this is in design - if i move the cursor close to it,
        // the notebar with another account avatar appears, so this should disappear.
        accountAvatarWrapper: Redux.Rules
            .marginLeft(R => R.option('showAccountAvatar') ? 16 : 0)
            .width(R => R.option('showAccountAvatar') ? 32 : 0)
            .scale(R => R.option('showAccountAvatar') ? 1 : 0)
            .transition([
                ANIMATIONS.notebar('margin-left'),
                ANIMATIONS.notebar('width'),
                ANIMATIONS.notebar('height'),
                ANIMATIONS.notebar('transform'),
            ]),
        separator: Redux.Rules
            .width(1)
            .height(24)
            .backgroundColor(colors.grey300)
            .marginHorizontal(16),
    });
