/** EXTERNALS **/


/** LOCALS **/


import i18n from 'utils/i18n';


/** HELPERS **/


/** MAIN **/


export const actionType = {
    update: 'update',
    publish: 'publish',
    unpublish: 'unpublish',
};

export const flags = {
    publicNotesV1: 'public-notes-v1',
};

export const noteBarViewIds = {
    publicNotes: 'PUBLIC_NOTES',
};

export const toasterIds = {
    ToasterCopyLink: 'PublicNotesCopyLink',
    ToasterError: 'PublicNotesPublishError',
    ToasterSuccess: 'PublicNotesPublishSuccess',
};


const userLocale = i18n.locale === 'en' ? '' : `${i18n.locale}/`;

export const links = {
    footerLinks: {
        digitalServicesActUrl: `${CONFIG.meistertask.url}/pages/${userLocale}digital-services-act`,
        complianceUrl: `${CONFIG.meistertask.url}/pages/${userLocale}compliance`,
        termsOfServiceUrl: `${CONFIG.meistertask.url}/pages/${userLocale}terms-conditions`,
        imprintUrl: `${CONFIG.meistertask.url}/pages/${userLocale}imprint`,
        privacyUrl: `${CONFIG.meistertask.url}/pages/${userLocale}privacy`,
    },
    404: `${CONFIG.meistertask.url}/pages/live-page-not-found`,
};

