/** EXTERNALS **/


import { constCase, typeCase } from '@meisterlabs/string';
import { enums as _enums, literal } from 'superstruct/umd/superstruct';


/** LOCALS **/


/** HELPERS **/


/** MAIN **/


/*
    const EnumT = enums(['foo-example', 'bar-example']);

    EnumT.FooExampleT == literal('foo-example');
    EnumT.BarExampleT == literal('bar-example');
*/
// TODO typescript variant might be needed
export const enums = function(values) {
    const struct = _enums(values);


    values.forEach(function(value) {
        Object.defineProperty(struct, typeCase(String(value)), {
            value: literal(value),
            enumerable: false,
        });

        Object.defineProperty(struct, constCase(String(value)), {
            value,
            enumerable: false,
        });
    });

    return struct;
};
