/** EXTERNALS **/


import { createContext } from 'react';


/** LOCALS **/


/** HELPERS **/


/** MAIN **/


export const JsonContext = createContext({});

export const TranslationsContext = createContext({});
