/** EXTERNALS **/


import snakecase from 'lodash.snakecase';


/** LOCALS **/


/** HELPERS **/


/** MAIN **/


export const snakeCase = function(string) {
    return snakecase(string);
};
