import { links } from 'features/PublicNotes/config';

export const redirectTo404 = function() {
    window.location.href = links[404];
};

export const fetchNoteData = async function({ noteToken, callback }) {
    if (DEVELOPMENT && !noteToken) {
        // Only to be used for dev server if no token is present
        import('./demo.json').then(data => callback(data.default));

        return;
    }

    try {
        const contentBaseUrl = CONFIG.meisternote.livePages.contentUrl || '';
        const contentUrl = `${contentBaseUrl}/notes/${noteToken}/content/content.json`;

        const response = await fetch(contentUrl);

        // Check if the response is OK (status code 200-299)
        if (!response.ok) {
            redirectTo404();
        }

        // Parse the response as JSON (assuming the content is JSON)
        const data = await response.json();

        // Return the data
        callback(data);
    } catch (error) {
        // Todo Error Report to Sentry
        // eslint-disable-next-line no-console
        redirectTo404();
    }
};
