import { rgba } from '@meisterlabs/ui';

import {
    withBorderedMarks,
    withHexColor,
    withInlineBold,
    withInlineCode,
    withInlineHighlight,
    withInlineItalic,
    withInlineLink,
    withInlineStrikethrough,
    withInlineUnderline,
    withLink,
    withLinkRenderer,
} from '@meisterlabs/editor';

export const withInlineRendering = function() {
    withInlineBold('mod+b');
    withInlineCode('mod+e');
    withInlineItalic('mod+i');
    withInlineStrikethrough('mod+-');
    withInlineUnderline('mod+u');
    withInlineLink();
    withLinkRenderer();
    withBorderedMarks(['code', 'highlight']);

    withInlineHighlight({
        colors: {
            grey: rgba.text(0.3),
            red: rgba.red(0.3),
            orange: rgba.orange(0.3),
            yellow: rgba.yellow(0.3),
            grass: rgba.grass(0.3),
            green: rgba.green(0.3),
            turquoise: rgba.turquoise(0.3),
            blue: rgba.blue(0.3),
            navy: rgba.navy(0.3),
            purple: rgba.purple(0.3),
            pink: rgba.pink(0.3),
        },
    });

    withLink();
    withHexColor();
};
