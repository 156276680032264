/** EXTERNALS **/


/** LOCALS **/


/** HELPERS **/


/** MAIN **/


export default class CompositeError {
    constructor(errors) {
        // [Error | GraphQLError | RequestError | ResponseError]
        this.errors = errors.flatMap(function(error) {
            return error instanceof CompositeError
                ? error.errors
                : [error];
        });
    }

    concat(error) {
        return new CompositeError([this, error]);
    }

    messages() {
        return this.errors.flatMap(error => error.messages());
    }

    statusCodes() {
        return this.errors.flatMap(error => error.statusCodes());
    }

    types() {
        return this.errors.flatMap(error => error.types());
    }

    log() {
        this.errors.forEach(error => error.log());
    }
}
