/** EXTERNALS **/


/** LOCALS **/


import fromStore from './fromStore';
import useStream from './useStream';


/** HELPERS **/


/** MAIN **/


export default function(id, isEqual) {
    return useStream(fromStore(id), isEqual);
}
