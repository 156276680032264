/** EXTERNALS **/


import camelcase from 'lodash.camelcase';


/** LOCALS **/


/** HELPERS **/


/** MAIN **/


export const camelCase = function(string) {
    return camelcase(string);
};
