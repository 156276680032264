import {
    withCodeSerializers,
    withEmbedSerializers,
    withFileSerializers,
    withHeaderSerializers,
    withHorizontalRuleSerializers,
    withHtmlDeserializer,
    withIconSerializers,
    withInlineBoldSerialization,
    withInlineCodeSerialization,
    withInlineHighlightSerialization,
    withInlineItalicSerialization,
    withInlineLinkSerialization,
    withInlineStrikethroughSerialization,
    withInlineUnderlineSerialization,
    withListSerializers,
    withLopSerializers,
    withMindMeisterSerializers,
    withParagraphSerializers,
    withSerializerEditor,
    withTocSerializers,
} from '@meisterlabs/editor';


import { types } from 'features/Editor/utils/blocks';


/**
 * The only reason for this function is to support the old editor code schema.
 * The new editor code schema uses a dataLanguage property in the code block instead of a class.
 * The old editor code schema uses a class to define the language, in the form of `language-<language>`.
 */
const withOldCodeSchemaDeseriaizer = function() {
    withHtmlDeserializer(
        (_, node) => (
            node.tagName === 'pre' &&
            node.children.length === 1 &&
            'tagName' in node.children[0] &&
            node.children[0].tagName === 'code' &&
            'properties' in node.children[0] &&
            'className' in node.children[0].properties &&
            node.children[0].properties.className[0].startsWith('language-')
        ),
        ({ multiple }, node) => {
            const code = node.children[0];

            const language = code.properties.className[0].replace('language-', '');

            return {
                type: 'code',
                properties: {
                    language,
                },
                children: multiple(code.children),
            };
        }
    );
};


export const withSerialization = function() {
    withSerializerEditor(function() {
        withOldCodeSchemaDeseriaizer();

        withListSerializers();
        withCodeSerializers();
        withEmbedSerializers();
        withFileSerializers();
        withHeaderSerializers();
        withLopSerializers();
        withMindMeisterSerializers();
        withParagraphSerializers();
        withTocSerializers();
        withHorizontalRuleSerializers();

        withIconSerializers({
            defaultProps: {
                ...types.icon.blockProperties,
                colorId: parseInt(types.icon.blockProperties.colorId),
                iconId: parseInt(types.icon.blockProperties.iconId),
            },
        });

        withInlineBoldSerialization();
        withInlineCodeSerialization();
        withInlineItalicSerialization();
        withInlineStrikethroughSerialization();
        withInlineUnderlineSerialization();
        withInlineLinkSerialization();

        withInlineHighlightSerialization({
            defaultColor: 'grey',
        });
    });
};
