/** EXTERNALS **/


import React from 'react';
import { Button, fonts } from '@meisterlabs/ui';
import { View } from '@meisterlabs/knightrider';


/** LOCALS **/


import { useStore } from 'utils/Stream';
import { useAction } from 'utils/Actions';
import i18n from 'utils/i18n';


/** HELPERS **/


const headerButtonFilled = Button.defaultProps.style.mergeOptions({
    height: 36,
    padding: 16,
    font: fonts.m.medium,
    borderRadius: 50,
});

const style = {
    wrapper: {
        alignItems: 'center',
        gap: 8,
    },
    headerButtonFilled,
    headerButtonOutline: headerButtonFilled.mergeOptions({
        bordered: true,
    }),
};


/** MAIN **/


const PublicButtons = function() {
    const CurrentRoute = useStore('CurrentRoute');
    const PublicNotes = useStore('PublicNotes.PublicNotes');
    const closePreview = useAction('PublicNotes.togglePreview');
    const publish = useAction('PublicNotes.publishNote');
    const updateNote = useAction('PublicNotes.updateNote');

    const noteId = CurrentRoute.noteId().getOrElse(null);
    const PublicNote = PublicNotes.getPublicNoteFromCurrentRoute({ CurrentRoute });

    const onClick = PublicNote
        ? () => updateNote({ noteId })
        : () => publish({ noteId });

    const buttonText = PublicNote
        ? i18n.t('public_notes.preview.buttons.update')
        : i18n.t('public_notes.preview.buttons.publish');

    return (
        <View style={style.wrapper}>
            <Button style={style.headerButtonOutline} onClick={() => closePreview()}>
                {i18n.t('public_notes.preview.buttons.edit')}
            </Button>
            <Button style={style.headerButtonFilled} onClick={onClick}>
                {buttonText}
            </Button>
        </View>
    );
};

export default PublicButtons;
