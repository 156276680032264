/** EXTERNALS **/


import { colors } from '@meisterlabs/ui';


/** LOCALS **/


/** HELPERS **/


/** MAIN **/


export const textStyle = {
    fontSize: 40,
    lineHeight: '55px',
    fontWeight: 'bold',
    color: colors.grey700,
};

export const rootStyle = {
    marginBottom: 40,
};
