/** EXTERNALS **/


/** LOCALS **/


/** HELPERS **/


/** MAIN **/


export const endsWith = function(string, ...params) {
    return string.endsWith(...params);
};
