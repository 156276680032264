/** EXTERNALS **/


import { fonts } from '@meisterlabs/ui';


/** LOCALS **/


import BREAKPOINTS from 'config/breakpoints';


/** HELPERS **/


// H1 font is a bit different to our standard font sizes, so we need to add it here
const h1Font = {
    ...fonts.xxl.bold,
    fontSize: 28,
};


/** MAIN **/


export const headerLevels = {
    1: h1Font,
    2: fonts.xl.bold,
    3: fonts.l.bold,
    4: fonts.m.bold,
    5: fonts.s.bold,
};

export const sidebar = {
    width: 320,
    minWidth: 320 - 80,
    maxWidth: 320 + 160,
};

export const noteBarWidth = 64;
export const headerHeight = 80;
export const coverHeight = 200;
export const noteIconSize = 80;
export const blockActionsDistance = 10;
export const nonEditableBlockMinHeight = 44;

export const editorPadding = breakpoint => {
    return breakpoint === BREAKPOINTS.small
        ? 20
        : 80;
};

export const editorMaxWidth = breakpoint => {
    return 900 - (editorPadding(breakpoint) * 2);
};

export const noteSpaces = {
    iconAndCover: {
        header: 0,
        cover: 0,
        icon: 10,
        buttons: 5,
    },
    iconOnly: {
        header: headerHeight,
        cover: 0,
        icon: 10,
        buttons: 5,
    },
    coverOnly: {
        header: 0,
        cover: 10,
        icon: 0,
        buttons: 5,
    },
    none: {
        header: headerHeight,
        cover: 0,
        icon: 0,
        buttons: 5,
    },
};

export const toolbar = {
    height: 44,
    space: 5,
    minDistanceToEdges: 30,
};

export const notificationSection = {
    width: 326,
    leftSpace: 76,
};
