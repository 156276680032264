/** EXTERNALS **/


import React from 'react';
import { colors } from '@meisterlabs/ui';


/** LOCALS **/


/** HELPERS **/


const style = {
    display: 'block',
    overflow: 'hidden',
    width: '100%',
    height: '1px',
    border: 'none',
    margin: '8px 0',
    padding: 0,
    background: colors.grey300,
};


/** MAIN **/


const Separator = function() {
    return (
        <hr style={style} />
    );
};

export default Separator;
