/** EXTERNALS **/


/** LOCALS **/


import status from './status';
import combine from './combine';
import * as ErrorUtils from 'utils/Error';


/** HELPERS **/


/** MAIN **/


export default function(streams) {
    let winningIdx = null;

    return combine(streams, function(streams, stream) {
        if (winningIdx != null) {
            const winning = streams[winningIdx];

            if (stream() === winning) return;
            return stream(winning);
        }

        const winning = streams.find(status.isReady);

        if (winning) {
            winningIdx = streams.indexOf(winning);

            return stream(winning);
        }

        const pending = streams.find(status.isPending);

        if (pending) return stream(pending);

        const errors = streams
            .filter(status.isError)
            .map(state => state.error);

        if (errors.length > 0) {
            const error = new ErrorUtils.CompositeError(errors);

            return stream(status.error(error));
        }

        const fallback = streams.find(state => !status.isError(state) && !status.isPending(state));

        stream(fallback);
    });
}
