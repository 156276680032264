/** EXTERNALS **/


import moment from 'moment';
import { Str } from '@meisterlabs/common';


/** LOCALS **/


/** HELPERS **/


// MindMeister gives us only "zh" for Chinese on the SessionOwner
// but moment differentiates between "cn", "hk", "tw" and has no "zh"
// so we default to China (Mainland) by converting "zh" to "zh-cn"
const normalizeLocale = function(locale) {
    return Str
        .of(locale)
        .toSwitch()
        .caseEquals('zh', () => 'zh-cn')
        .default(locale => locale)
        .get();
};


/** MAIN **/


export const updateLocale = function({ locale }) {
    moment.locale(normalizeLocale(locale));
};


export const updateStartOfWeek = function({ locale, startOfWeek }) {
    moment.updateLocale(normalizeLocale(locale), {
        week: {
            dow: startOfWeek,
        },
    });
};
