/** EXTERNALS **/


import pascalcase from 'pascalcase';


/** LOCALS **/


/** HELPERS **/


/** MAIN **/


export const pascalCase = function(string) {
    return pascalcase(string);
};
