/** EXTERNALS **/


/** LOCALS **/


/** HELPERS **/


/** MAIN **/


export default {
    light: 'light',
    dark: 'dark',
    systemDefault: 'system-default',
};
