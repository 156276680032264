/** EXTERNALS **/


/** LOCALS **/


/** HELPERS **/


/** MAIN **/


export const lowerCase = function(string) {
    return string.toLowerCase();
};
