/** EXTERNALS **/


import React from 'react';


/** LOCALS **/


import { JsonContext, TranslationsContext } from '../utils/context';
import Root from '../components/PublicNotes/Root';

import i18n from 'utils/i18n';


/** HELPERS **/


const publicTranslations = {
    attribution: i18n.t('cover.attribution'),
};


/** MAIN **/


// To be used by both the Preview and the PublicWrapper components to provide the necessary contexts
const ContextWrapper = function({ json, preview }) {
    return (
        <TranslationsContext.Provider value={publicTranslations}>
            <JsonContext.Provider value={json}>
                <Root preview={preview} />
            </JsonContext.Provider>
        </TranslationsContext.Provider>
    );
};

export default ContextWrapper;
