/** EXTERNALS **/


import * as Context from '@meisterlabs/hooks-context';
import { object, validate } from '@meisterlabs/types';


/** LOCALS **/


/** HELPERS **/


/** MAIN **/


export const assertProps = function(props, type) {
    if (process.env.NODE_ENV === 'production') return;

    const ctx = Context.peek();

    const name = ctx.type === 'RenderContext'
        ? `<${ctx.name}>`
        : '<Unknown>';

    const [err] = validate(
        { [name]: { props } },
        object({ [name]: object({ props: type }) }),
        { coerce: false },
    );

    // eslint-disable-next-line no-console
    if (err) console.error(err);
};
