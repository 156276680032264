/** EXTERNALS **/


import { colors, rgba } from '@meisterlabs/ui';


/** LOCALS **/


/** HELPERS **/


/** MAIN **/


export default {
    light: {
        backgroundColor: colors.grey100,
        rootNoteNameColor: colors.grey500,
        highlightColor: rgba.black(0.05),
        noteNameColor: colors.grey700,
        iconColor: colors.grey700,
        noteIconColor: colors.white,
        noteIconBackgroundColor: colors.grey700,
        disclosureIconColor: rgba.black(0.2),
        textColor: colors.black,
        buttonBackgroundColor: rgba.grey1000(0.1),
    },
    dark: {
        backgroundColor: colors.grey900,
        rootNoteNameColor: colors.grey500,
        highlightColor: rgba.white(0.1),
        noteNameColor: colors.white,
        iconColor: colors.white,
        noteIconColor: colors.white,
        noteIconBackgroundColor: colors.grey700,
        disclosureIconColor: colors.white,
        textColor: colors.white,
        buttonBackgroundColor: rgba.white(0.1),
    },
}
