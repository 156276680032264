import {
    withBlockDepth,
    withBlockSpacing,
    withCheckList,
    withCode,
    withEmbed,
    withHeader,
    withHorizontalRule,
    withIcon,
    withOrderedList,
    withParagraph,
    withSyntaxHighlighter,
    withToc,
    withUnorderedList,
} from '@meisterlabs/editor';
import '@meisterlabs/editor/dist/style.css';


import { withDefault } from '../renderers/withDefault';

import i18n from 'utils/i18n';
import BLOCK_ICONS from 'config/Blocks/blockIcons.js';

// Direct imports since Importing from index file triggers dependency on Redux
import { types } from 'features/Editor/utils/blocks';
import { withInlineRendering } from 'src/features/Editor/utils/withInlineRendering';
import blockSpacing from 'features/Editor/utils/blockSpacing';


export const withRendering = function(props) {
    const {
        noteToken,

        // Optional
        scrollToBlock = () => null,
    } = props;

    const blocksWithDepth = [
        types.orderedList.type,
        types.unstyled.type,
        types.icon.type,
        types.code.type,
    ];

    withParagraph();

    withInlineRendering();
    withHeader();
    withHorizontalRule();

    withOrderedList({
        blocksWithDepth,
        blocksThatAlwaysContinue: ['file'],
    });

    withUnorderedList();

    withCheckList({});

    withBlockDepth({ blocksWithDepth });

    withEmbed({
        defaultPlaceholder: i18n.t('embeds.share_link_default'),
        typePickerHeader: i18n.t('actions.select_service'),
        placeholderForType(name) {
            return i18n.t('embeds.share_link_named', { name });
        },
    });

    withIcon({
        icons: BLOCK_ICONS.icons,
        colors: BLOCK_ICONS.colors,
        iconPickerHeader: i18n.t('blocks.icon'),
        defaultProps: {
            ...types.icon.blockProperties,
            colorId: parseInt(types.icon.blockProperties.colorId),
            iconId: parseInt(types.icon.blockProperties.iconId),
        },
    });

    withToc({
        emptyMessage: i18n.t('table-of-contents.empty-message'),
        maxLevel: 2,
        onClickItem: ({ key }) => {
            // Todo implement public tracking
            // https://linear.app/meisterlabs/issue/GRO-86/public-note-tracking
            // analytics.selectedItemInToc({ source: 'block' });

            scrollToBlock({ key, noteToken });
        },
    });

    withCode({
        noneItemText: i18n.t('generic.none'),
    });

    withSyntaxHighlighter();
    withBlockSpacing({ getPadding: blockSpacing });

    withDefault();
};
