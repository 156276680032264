/** EXTERNALS **/


/** LOCALS **/


/** HELPERS **/


// Since the `data-key` attribute is not set on the root element of the block,
// we need to find the root element by looking for the closest element
const getSlateElement = function(blockKey, parentElement) {
    return parentElement
        .querySelector(`[data-key="${blockKey}"].element`)
        ?.closest('[data-slate-editor="true"] > div');
};


/** MAIN **/


export const scrollToBlock = function({ animate = true, blockKey, parentSelector }) {
    const parentElement = parentSelector
        ? document.querySelector(parentSelector)
        : document;

    const element = parentElement.querySelector(`[data-block-key="${blockKey}"]`)
        ?? getSlateElement(blockKey, parentElement);

    // Block not loaded.
    // Editor will scroll to block when it loads.
    if (!element) return;

    const scroller = element
        .closest('.knightrider-scrollview-scrollelement');

    scroller
        .scrollTo({
            top: element.offsetTop,
            behavior: animate ? 'smooth' : 'instant',
        });
};
