/** EXTERNALS **/


import React from 'react';
import { Image, View } from '@meisterlabs/knightrider';
import { s128 as spinner } from '@meisterlabs/images/spinners';


/** LOCALS **/


import ZINDEX from 'config/z-index';


/** HELPERS **/


const style = {
    root: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: 'white',
        zIndex: ZINDEX.loadingScreen,
    },
    spinner: {
        width: 64,
        height: 64,
    },
};


/** MAIN **/


const FullScreenSpinner = function() {
    return (
        <View style={style.root}>
            <Image
                src={spinner}
                style={style.spinner}
            />
        </View>
    );
};

export default FullScreenSpinner;
