/** EXTERNALS **/


import React from 'react';
import { Text } from '@meisterlabs/knightrider';


/** LOCALS **/


import { textStyle } from '../style';


/** HELPERS **/


/** MAIN **/


export default function(props) {
    const {
        value,
        fontFamily,
    } = props;

    const style = {
        ...textStyle,
        fontFamily,
    };

    return (
        <Text style={style}>
            {value}
        </Text>
    );
}
