/** EXTERNALS **/


import React from 'react';
import { ScrollView, View } from '@meisterlabs/knightrider';


/** LOCALS **/


import Slate from './Slate';
import NoteHeader from './NoteHeader';
import PublicHeader from './PublicHeader';
import PublicFooter from './PublicFooter';
import { useScroll } from '../../hooks/useScroll';


/** HELPERS **/


const style = {
    root: {
        flexDirection: 'column',
        height: '100%',
        flexGrow: 1,
    },
};


/** MAIN **/


// The root component for the PublicNotes feature wrapping together the Building blocks
const Root = function({ preview }) {
    const { isScrolled, onScroll } = useScroll();

    return (
        <View style={style.root} className="public-page-root">
            <PublicHeader preview={preview} isScrolled={isScrolled} />
            <ScrollView
                autoHeight
                onScroll={onScroll}
            >
                <NoteHeader />
                <Slate />
                <PublicFooter />
            </ScrollView>
        </View>
    );
};

export default Root;
