/** EXTERNALS **/


import flyd from 'flyd';


/** LOCALS **/


import streamOf from './of';


/** HELPERS **/


/** MAIN **/


const fromValue = function(...params) {
    return streamOf(flyd.stream(...params));
};

export default fromValue;
