import { types } from './blocks';

const listTypes = [
    types.orderedList.type,
    types.unorderedList.type,
    types.checkList.type,
];

const boxedTypes = [
    types.file.type,
    types.meistertask.type,
    types.mindmap.type,
    types.code.type,
    types.icon.type,
    types.embed.type,
];

const isListType = function(Block) {
    return listTypes.includes(Block.type);
};

const isBoxedType = function(Block) {
    return boxedTypes.includes(Block.type);
};

export default function(BlockA, BlockB) {
    if (BlockA == null || BlockB == null) {
        return 0;
    }

    if (BlockA.type === types.horizontalRule.type || BlockB.type === types.horizontalRule.type) {
        return 0;
    }

    if (BlockB.type === types.header1.type && BlockB.level === 1) {
        return 30;
    }

    if (BlockA.type === types.header3.type && BlockA.level >= 3) {
        return 10;
    }

    if (isListType(BlockA) && isListType(BlockB)) {
        return 10;
    }

    if (BlockA.type === types.meistertask.type && BlockB.type === types.meistertask.type) {
        return 10;
    }

    if (isBoxedType(BlockA) && isBoxedType(BlockB)) {
        return 20;
    }

    if (isBoxedType(BlockA) || isBoxedType(BlockB)) {
        return 30;
    }

    return 20;
}
