/** EXTERNALS **/


import { Arr } from '@meisterlabs/common';


/** LOCALS **/


import CompositeError from './CompositeError';


/** HELPERS **/


/** MAIN **/


export default class ResponseError {
    constructor(error, state) {
        // Axios error
        this.error = error;

        // Stream state
        this.state = state;
    }

    concat(error) {
        return new CompositeError([this, error]);
    }

    messages() {
        return Arr
            .of([
                this.error.message,
                this.error.response.statusText,
                this.error.response.data,
            ])
            .compact()
            .get();
    }

    statusCodes() {
        return [this.error.response.status];
    }

    types() {
        return ['ResponseError'];
    }

    log() {
        // eslint-disable-next-line
        console.log(this.state);
        // eslint-disable-next-line
        console.error(this.error);
    }
}
