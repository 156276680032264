/** EXTERNALS **/


/** LOCALS **/


/** HELPERS **/


/** MAIN **/


const status = {
    ok: { status: 'ok', reason: null, error: null },
    pending: { status: 'pending', reason: null, error: null },
    abort: reason => ({ status: 'abort', reason }),
    error: error => ({ status: 'error', error }),
    isPending(value) {
        return value?.status === 'pending';
    },
    isAbort(value) {
        return value?.status === 'abort';
    },
    isError(value) {
        return value?.status === 'error';
    },
    isReady(value) {
        return value !== undefined
            && !status.isPending(value)
            && !status.isAbort(value)
            && !status.isError(value);
    },
    isAnyPending(values) {
        return values.some(status.isPending);
    },
    isAnyAbort(values) {
        return values.some(status.isAbort);
    },
    isAnyError(values) {
        return values.some(status.isError);
    },
    areAllReady(values) {
        return values.every(status.isReady);
    },
};

export default status;
