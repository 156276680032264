/** EXTERNALS **/


import { yTextToSlateElement } from '@slate-yjs/core';
import * as Y from 'yjs';


/** LOCALS **/


/** HELPERS **/


/** MAIN **/


export const toBlocks = function(yjsData) {
    // Handle an empty state, which is not a valid editor state in our model.
    // - base64: AAA=
    // - binary: \x00\x00
    // - slate: {text: ""}
    if (yjsData === 'AAA=') {
        return [];
    }

    const yDoc = new Y.Doc();
    const sharedType = yDoc.get('content', Y.XmlText);
    const buffer = Uint8Array.from(atob(yjsData), c => c.charCodeAt(0));

    Y.applyUpdate(yDoc, buffer);

    const root = yTextToSlateElement(sharedType);

    return root.children;
};
