/** EXTERNALS **/


import React from 'react';
import { View } from '@meisterlabs/knightrider';
import { Logo as MeisterTaskLogo } from '@meisterlabs/svgs/MeisterTask';


/** LOCALS **/


import { RAISED_BOX_SHADOW } from 'src/containers/Note.Header/Default/style';
import PublicButtons from './PublicButtons';
import PreviewButton from '../MeisterNote/PreviewButtons';


/** HELPERS **/


const boxShadow = RAISED_BOX_SHADOW
    .map(shadow => `${shadow.color} 0px ${shadow.v}px ${shadow.blur}px ${shadow.spread}px`)
    .join(', ');

const style = {
    wrapper({ isScrolled }) {
        return {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            padding: '24px 60px',
            transition: 'box-shadow 0.3s ease-in-out',
            boxShadow: isScrolled ? boxShadow : 'none',
        };
    },
    logo: {
        width: 100,
    },
    navButtons: {
        alignItems: 'center',
        gap: 24,
    },
};


/** MAIN **/


export default function({ preview, isScrolled }) {
    return (
        <View style={style.wrapper({ isScrolled })}>
            <View style={style.logo}>
                <MeisterTaskLogo />
            </View>
            {preview
                ? <PreviewButton />
                : <PublicButtons />}

        </View>
    );
}
