/** EXTERNALS **/


import * as Context from '@meisterlabs/hooks-context';


/** LOCALS **/


/** HELPERS **/


/** MAIN **/


export const useName = function(name) {
    const ctx = Context.peek();

    if (ctx.type !== 'RenderContext') {
        throw new Error('useName was used outside of useRenderContext.');
    }

    ctx.name = name;
};
