/** EXTERNALS **/


/** LOCALS **/


import combine from './combine';
import status from './status';
import * as ErrorUtils from 'utils/Error';


/** HELPERS **/


/** MAIN **/


const combineReady = function(streams, fn) {
    return combine(streams, function(states, stream, changed) {
        const errors = states
            .filter(status.isError)
            .map(state => state.error);

        if (errors.length > 0) {
            const error = new ErrorUtils.CompositeError(errors);

            return stream(status.error(error));
        }

        // TODO CompositeAbort?
        const abort = states.find(status.isAbort);

        if (abort) return stream(abort);

        // TODO CompositePending?
        const pending = states.find(status.isPending);

        if (pending) return stream(pending);

        fn(states, stream, changed);
    });
};

export default combineReady;
