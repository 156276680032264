/* eslint global-require: 0 */
/** LOCALS **/


/** MAIN **/


let App;
let Root;

require('utils/i18n').default.load(function() {
    App = require('./App').default;
    Root = require('./Root').default;

    App.render({
        render: Root,
        target: DOM_CONTAINER,
    });
});

