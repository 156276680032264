/** EXTERNALS **/


import { noteIcons } from '@meisterlabs/svg-helpers';


/** LOCALS **/


import THEMES from 'config/themes';
import themes from 'assets/themes';


/** HELPERS **/


const noteSettingName = 'note_icon';

const {
    iconColors,
    primaryIcons,
    primaryType,
    secondaryIcons,
    secondaryType,
} = noteIcons;


/** MAIN **/


const buildIconData = function(icon, color) {
    icon = icon ?? primaryIcons[0];
    color = color ?? iconColors[0];

    return {
        id: icon.id,
        type: icon.type,
        colorId: color.id,
    };
};

const getIconData = function(iconSetting) {
    if (!iconSetting) {
        return buildIconData(null, null);
    }

    const { id, type, selectedColor } = iconSetting;

    const iconSet = ({
        [primaryType]: primaryIcons,
        [secondaryType]: secondaryIcons,
    })[type];

    const icon = iconSet?.find(item => item.id === id);
    const color = iconColors.find(item => item.id === selectedColor);

    return buildIconData(icon, color);
};

const getColor = function(iconData, theme = THEMES.light) {
    return noteIcons.getColor(iconData) || themes[theme].iconColor;
};

export default {
    ...noteIcons,
    settingName: noteSettingName,
    getIconData,
    getColor,
};
