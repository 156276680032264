/** EXTERNALS **/


import flyd from 'flyd';
import { shallowEqualArrays } from 'shallow-equal';


/** LOCALS **/


import streamOf from './of';
import uniq from './uniq';


/** HELPERS **/


/** MAIN **/


// TODO allow objects
const combine = function(streams, fn) {
    const deps = streams.map(v => v.stream);

    let lastStates = [];

    const stream = flyd.combine(function(...params) {
        const streams = params.slice(0, deps.length);
        const self = params[deps.length];
        const changed = params[deps.length + 1];
        const states = streams.map(stream => stream());

        if (shallowEqualArrays(lastStates, states)) return;

        lastStates = states;

        fn(states, uniq(self), changed);
    }, deps);

    return streamOf(stream);
};

export default combine;
