/** EXTERNALS **/


import React, { useContext } from 'react';
import { View } from '@meisterlabs/knightrider';


/** LOCALS **/


import { JsonContext } from '../../utils/context';
import { PublicNoteCover } from 'src/features/NoteCover';
import { PublicNoteIcon } from 'src/features/NoteIcon';
import Publisher from './Publisher';
import { default as PublicNoteTitle } from 'src/features/NoteTitle/components/PublicNoteTitle';
import Separator from './Separator';


/** HELPERS **/


const style = {
    root: {
        flexDirection: 'column',
        alignItems: 'center',
    },
    centeredWrapper: ({ hasCover, hasIcon }) => ({
        maxWidth: 900,
        width: '100%',
        flexDirection: 'column',
        gap: 24,
        marginTop: hasCover && hasIcon ? 0 : 24,
        marginBottom: 24,
    }),
};


/** MAIN **/


export default function() {
    const json = useContext(JsonContext);

    return (
        <View style={style.root}>
            <PublicNoteCover json={json} />
            <View style={style.centeredWrapper({ hasCover: !!json.cover, hasIcon: !!json.icon })}>
                <PublicNoteIcon json={json} />
                <PublicNoteTitle json={json} />
                <Publisher json={json} />
                <Separator />
            </View>
        </View>
    );
}
