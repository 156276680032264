/** EXTERNALS **/


/** LOCALS **/


/** HELPERS **/


/** MAIN **/


export default {
    serif: 'serif',
    sansSerif: 'sans-serif',
    mixed: 'mixed',
    fontFamilies: {
        serif: 'Lora',
        'sans-serif': '"Avenir", "Avenir Next", "Segoe UI", Helvetica, Arial, sans-serif',
    },
};
