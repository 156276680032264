/** EXTERNALS **/


import React from 'react';
import { Text, View } from '@meisterlabs/knightrider';
import { colors, fonts } from '@meisterlabs/ui';
import { Hyperlink } from '@meisterlabs/react-links';
import { Logo as MeisterTaskLogo } from '@meisterlabs/svgs/MeisterTask';


/** LOCALS **/


import i18n from 'src/utils/i18n';
import parseI18nHtml from 'src/utils/parseI18nHtml';
import { links } from '../../config';
import FacebookGrey from 'assets/icons/SocialMedia/FacebookGrey.svg';
import YoutubeGrey from 'assets/icons/SocialMedia/YoutubeGrey.svg';
import LinkedinGrey from 'assets/icons/SocialMedia/LinkedinGrey.svg';


/** HELPERS **/


const style = {
    wrapper: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        minHeight: 248,
        padding: '20px 140px',
        marginTop: 63,
        backgroundColor: colors.grey100,
    },
    footerHeader: {
        alignItems: 'center',
        gap: 8,
    },
    boldHeader: {
        ...fonts.l.bold,
    },
    logo: {
        height: 36,
    },
    footerText: {
        flexDirection: 'column',
        alignItems: 'center',
        gap: 8,
        padding: '24px 0',
    },
    footerCta: {
        flexDirection: 'column',
        alignItems: 'center',
    },
    footerCtaText: {
        color: colors.grey500,
        ...fonts.m.medium,
    },
    footerCtaLink: {
        color: colors.grey500,
        ...fonts.m.medium,
        textDecoration: 'underline',
    },
    legalFooter: {
        justifyContent: 'center',
        alignItems: 'center',
        gap: 37,
        width: '100%',
    },
    hyperlinks: {
        gap: 24,
        alignItems: 'center',
    },
    hyperlink: {
        color: colors.grey500,
        ...fonts.m.regular,
    },
    inc: {
        color: colors.grey500,
        ...fonts.m.bold,
    },
    socialMedia: {
        gap: 10,
        height: 24,
    },
};

const footerLinks = [
    {
        text: 'Digital Services Act',
        url: links.footerLinks.digitalServicesActUrl,
    },
    {
        text: i18n.t('generic.footer.compliance'),
        url: links.footerLinks.complianceUrl,
    },
    {
        text: i18n.t('generic.footer.terms_of_service'),
        url: links.footerLinks.termsOfServiceUrl,
    },
    {
        text: i18n.t('generic.footer.imprint'),
        url: links.footerLinks.imprintUrl,
    },
    {
        text: i18n.t('generic.footer.privacy'),
        url: links.footerLinks.privacyUrl,
    },
];

const parseWithLinks = function(text, url) {
    return parseI18nHtml(text)
        .map(function({ type, value }, index) {
            switch (type) {
            case 'SignupLink':
                return (
                    <Hyperlink
                        key={index}
                        blank
                        url={url}
                        style={style.footerCtaLink}
                    >
                        {value}
                    </Hyperlink>
                );
            default:
                return value;
            }
        });
};

const SocialMediaLink = ({ url, Icon }) => (
    <Hyperlink url={url} blank>
        <Icon />
    </Hyperlink>
);

const socialMediaLinks = [
    { url: 'https://www.facebook.com/meistertask', Icon: FacebookGrey },
    { url: 'https://www.youtube.com/meistertask', Icon: YoutubeGrey },
    { url: 'https://www.linkedin.com/company/meisterlabs-gmbh', Icon: LinkedinGrey },
];


/** MAIN **/


export default function() {
    return (
        <View style={style.wrapper}>
            <View style={style.footerText}>
                <View style={style.footerHeader}>
                    <Text style={style.boldHeader}>
                        {i18n.t('public_notes.public.footer.header')}
                    </Text>
                    <View style={style.logo}>
                        <MeisterTaskLogo />
                    </View>
                </View>
                <View style={style.footerCta}>
                    <Text style={style.footerCtaText}>
                        {i18n.t('public_notes.public.footer.cta_question')}
                    </Text>
                    <Text style={style.footerCtaText}>
                        {parseWithLinks(i18n.t('public_notes.public.footer.cta_link'), CONFIG.meisternote.accountUrl)}
                    </Text>
                </View>
            </View>
            <View style={style.legalFooter}>
                <View style={style.hyperlinks}>
                    <Text style={style.inc}>
                        © 2024 MeisterLabs
                    </Text>
                    {footerLinks.map((link, index) => (
                        <Hyperlink
                            key={index}
                            style={style.hyperlink}
                            url={link.url}
                            blank
                        >
                            {link.text}
                        </Hyperlink>
                    ))}
                </View>
                <View style={style.socialMedia}>
                    {socialMediaLinks.map(({ url, Icon }, index) => (
                        <SocialMediaLink key={index} url={url} Icon={Icon} />
                    ))}
                </View>
            </View>
        </View>
    );
}
