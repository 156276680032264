/** EXTERNALS **/


/** LOCALS **/


/** HELPERS **/


/** MAIN **/


export default {
    small: 'small',
    medium: 'medium',
    default: 'default',
};
