/** EXTERNALS **/


import React from 'react';
import { Text, View } from '@meisterlabs/knightrider';
import { colors, fonts } from '@meisterlabs/ui';
import { Str } from '@meisterlabs/common';
import Avatar from '@meisterlabs/react-avatars';
import moment from 'moment';


/** LOCALS **/


/** HELPERS **/


const style = {
    wrapper: {},
    avatarWrapper: {
        marginRight: 16,
    },
    textWrapper: {
        flexDirection: 'column',
    },
    name: {
        ...fonts.m.bold,
    },
    date: {
        ...fonts.s.regular,
        color: colors.grey500,
    },
};


/** MAIN **/


const Publisher = function({ json }) {
    const publisher = json.publisher;

    const name = `${publisher.firstname} ${publisher.lastname}`;

    const publishDate = Str
        .of(moment(json.publishedAt).format('MMMM DD, YYYY'))
        .upperCase()
        .get();

    return (
        <View style={style.wrapper}>
            <View style={style.avatarWrapper}>
                <Avatar
                    size={40}
                    url={publisher.avatarUrl}
                    lastName={publisher.lastname}
                    firstName={publisher.firstname}
                />
            </View>
            <View style={style.textWrapper}>
                <Text style={style.name}>
                    {name}
                </Text>
                <Text style={style.date}>
                    {publishDate}
                </Text>
            </View>

        </View>
    );
};

export default Publisher;
