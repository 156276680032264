/** EXTERNALS **/


import React from 'react';
import { View } from '@meisterlabs/knightrider';


/** LOCALS **/


import FONT_THEMES from 'config/fontThemes';
import ReadOnly from './ReadOnly';


/** HELPERS **/


const style = {
    root: {
        marginTop: 12,
    },
};


/** MAIN **/


const PublicNoteTitle = function({ json }) {
    const readOnlyProps = {
        value: json.noteName,
        fontFamily: FONT_THEMES.fontFamilies[FONT_THEMES.sansSerif],
    };

    return (
        <View style={style.root}>
            <ReadOnly {...readOnlyProps} />
        </View>
    );
};

export default props => <PublicNoteTitle {...props} />;
