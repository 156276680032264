/** EXTERNALS **/


/** LOCALS **/


/** HELPERS **/


const create = function() {
    const editors = new Map();
    const listeners = new Map();
    const temporarySelectionFns = new Map();

    const ensureListeners = noteId => {
        if (!listeners.has(noteId)) {
            listeners.set(noteId, []);
        }
    };

    return {
        get: noteId => {
            if (!editors.has(noteId)) return null;

            return editors.get(noteId);
        },
        set: (noteId, value) => {
            editors.set(noteId, value);
            ensureListeners(noteId);
        },
        delete: noteId => {
            if (!editors.has(noteId)) {
                throw new Error(`NoteId ${noteId} does not exist in the map`);
            }

            editors.delete(noteId);
            listeners.delete(noteId);
            temporarySelectionFns.delete(noteId);
        },
        dispatch: noteId => {
            if (!listeners.has(noteId)) {
                throw new Error(`NoteId ${noteId} does not exist in the listeners`);
            }

            for (const listener of listeners.get(noteId)) {
                listener();
            }
        },
        subscribe: (noteId, listener) => {
            ensureListeners(noteId);
            listeners.get(noteId).push(listener);

            // Call initially.
            listener();

            return () => {
                // It is possible that the editor has been removed before the listener is unsubscribed.
                if (!listeners.has(noteId)) return;

                const index = listeners.get(noteId).indexOf(listener);

                if (index !== -1) {
                    listeners.get(noteId).splice(index, 1);
                }
            };
        },

        // Temporary selection is used to highlight a selection while the user is drafting the first comment.
        setTemporarySelectionFn: (noteId, fn) => {
            temporarySelectionFns.set(noteId, fn);
        },
        getTemporarySelectionFn: noteId => {
            if (!temporarySelectionFns.has(noteId)) return;

            return temporarySelectionFns.get(noteId);
        },
    };
};


/** MAIN **/


export default create();
