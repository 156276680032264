/** EXTERNALS **/


import React from 'react';
import Redux from '@meisterlabs/redux';
import { Maybe } from '@meisterlabs/common';


/** LOCALS **/


import buildAttribution from '../utils/buildAttribution';
import Cover from './Cover';
import { useStore } from 'utils/Stream';


/** HELPERS **/


const memoizeBuildAttribution = Redux.createMemoizer({
    name: 'Editor/default/controller/buildAttribution',
    type: [String, String, String],
    max: 1,
});

export const memoizedBuildAttribution = memoizeBuildAttribution(buildAttribution);


/** MAIN **/


const MeisterNoteCover = function({ noteToken, onCover }) {
    const Covers = useStore('Covers');
    const NoteSettings = useStore('NoteSettings');
    const Platform = useStore('Platform');
    const Notes = useStore('Notes');

    const cover = NoteSettings.getCover({
        Covers,
        Notes,
        noteToken,
    });

    const attribution = Maybe
        .of(cover)
        .filter(cover => cover.type === 'unsplash')
        .filter(cover => !!cover.author)
        .filter(cover => !!cover.authorUrl)
        .map(cover => memoizedBuildAttribution(
            cover.author,
            cover.authorUrl,
            cover.imageLink,
        ))
        .getOrElse(null);

    const isMobile = Platform.isMobile();

    return (
        <Cover
            coverValue={cover.coverValue}
            attribution={attribution}
            isMobile={isMobile}
            onCover={onCover}
        />
    );
};

export default MeisterNoteCover;
