/** EXTERNALS **/


/** LOCALS **/


/** HELPERS **/


/** MAIN **/


export default {
    utm: 'utm_source=meisternote&utm_medium=referral&utm_campaign=api-credit',
};
