/** EXTERNALS **/


/** LOCALS **/


/** HELPERS **/


/** MAIN **/


export const startsWith = function(string, ...params) {
    return string.startsWith(...params);
};
