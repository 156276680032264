/** EXTERNALS **/


/** LOCALS **/


/** HELPERS **/


const CUBIC_OUT = 'cubic-bezier(0.33, 1, 0.68, 1)';

const editorExpandCollapseDurationMs = 500;

const editorExpandCollapseAnimation = property => ({
    property,
    duration: `${editorExpandCollapseDurationMs}ms`,
    easing: CUBIC_OUT,
});


/** MAIN **/


export default {
    editorExpandCollapseDurationMs,
    sidebar: editorExpandCollapseAnimation,
    notebar: editorExpandCollapseAnimation,
    headerButtons: editorExpandCollapseAnimation,
    sidebarDroppableFadeout: property => ({
        property,
        duration: '150ms',
    }),
};
