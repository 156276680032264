import React from 'react';

const styles = {
    root: {
        width: '100%',
        paddingTop: '2px',
        maxWidth: '900px',
        margin: '0 auto 0 auto',
    },
};

export const EditorWrapper = function({ children }) {
    return (
        <div
            className="editor-wrapper"
            style={styles.root}
        >
            {children}
        </div>
    );
};
