/** EXTERNALS **/


import { assert } from './superstruct';


/** LOCALS **/


/** HELPERS **/


/** MAIN **/


export const assertDev = function(value, type) {
    if (process.env.NODE_ENV === 'production') return;

    assert(value, type);
};
