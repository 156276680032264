/** EXTERNALS **/


import hash from 'hash-sum';
import { colors } from '@meisterlabs/ui';
import { GridHelpers } from '@meisterlabs/react-grid';


/** LOCALS **/


import UNSPLASH from 'config/unsplash';


/** HELPERS **/


export const getCoverValueFromUrl = function(url) {
    return `url("${url}") center/cover no-repeat`;
};

export const mnImageUrl = id => `https://www.meistertask.com/images/wallpapers/${id}.jpg`;

export const unsplashImageUrl = unsplash => `${unsplash.url}&${UNSPLASH.utm}`;

export const solidColors = [
    colors.grey700,
    colors.grey300,
    colors.blue,
    colors.green,
    colors.orange,
    colors.yellow,
];

export const createSolid = function(id) {
    const value = solidColors[id];

    return {
        id,
        compositeId: `solid_${id}`,
        type: 'solid',
        value,
        gridValue: value,
        gridType: GridHelpers.GRID_TYPES.COLOR,
        coverValue: value,
    };
};

export const createMNImage = function(id) {
    const url = mnImageUrl(id);

    return {
        id,
        compositeId: `mn_image_${id}`,
        type: 'mn_image',
        value: url,
        gridValue: `https://www.meistertask.com/images/wallpapers/${id}-thumb.jpg`,
        gridType: GridHelpers.GRID_TYPES.IMAGE,
        coverValue: getCoverValueFromUrl(url),
    };
};

export const createCustomImage = function(Cover) {
    const { id, url } = Cover.state;

    return {
        id,
        compositeId: `custom_image_${id}`,
        type: 'custom_image',
        value: url,
        gridValue: url,
        gridType: GridHelpers.GRID_TYPES.IMAGE,
        coverValue: getCoverValueFromUrl(url),
    };
};

export const createUnsplash = function(unsplash) {
    const id = hash(unsplash.url);

    return {
        compositeId: `unsplash_${id}`,
        type: 'unsplash',
        value: unsplash.url,
        gridValue: unsplash.thumbUrl,
        gridType: GridHelpers.GRID_TYPES.IMAGE,
        coverValue: getCoverValueFromUrl(unsplashImageUrl(unsplash)),
        // Custom
        author: unsplash.author,
        authorUrl: unsplash.authorUrl,
        imageLink: unsplash.imageLink
            // Backwards compatibility.
            || 'https://unsplash.com',
    };
};


/** MAIN **/


export const solids = [
    createSolid(0),
    createSolid(1),
    createSolid(2),
    createSolid(3),
    createSolid(4),
    createSolid(5),
];

export const mnImages = [
    createMNImage(95858824),
    createMNImage(169297610),
    createMNImage(129868538),
    createMNImage(220021975),
    createMNImage(106504736),
    createMNImage(98284103),
];
