/** EXTERNALS **/


import { Maybe, Switch } from '@meisterlabs/common';
import moment from 'moment';


/** LOCALS **/


import ICONS from 'src/config/icons';


/** HELPERS **/


/** MAIN **/


const getCoverJson = function({ NoteSettings, Covers, noteId }) {
    const NoteSetting = NoteSettings
        .toArr()
        .find(NS => NS.equalsNoteIdAndName(noteId, 'note_cover'))
        .toMaybe()
        .map(NS => NS.state.value);

    if (NoteSetting.isNothing()) return null;

    const id = NoteSetting.map(cover => cover.id).getOrElse(null);
    const type = NoteSetting.map(cover => cover.type).getOrElse(null);
    const data = NoteSetting.map(cover => cover.data).getOrElse(null);

    const url = NoteSetting
        .map(function(cover) {
            if (cover.type === 'custom_image') {
                return Maybe
                    .of(Covers.findById(cover.id))
                    .map(Cover => Cover.state.url)
                    .getOrElse(null);
            }

            return null;
        })
        .getOrElse('');

    // Maintain consistency with the API
    return Switch
        .of(type)
        .caseEquals('unsplash', () => {
            return {
                type,
                data,
                url,
            };
        })
        .default(() => {
            return {
                id,
                type,
                url,
            };
        })
        .get();
};

const getIconJson = function({ NoteSettings, noteId }) {
    return NoteSettings
        .toArr()
        .find(NS => NS.equalsNoteIdAndName(noteId, ICONS.settingName))
        .toMaybe()
        .map(NS => NS.state.value)
        .getOrElse(null);
};

const getPublisherJson = function({ SessionOwner }) {
    const { firstname, lastname, avatar } = SessionOwner.state;

    return {
        firstname,
        lastname,
        avatarUrl: avatar,
    };
};


export default function(props) {
    const {
        NoteSettings,
        Notes,
        Covers,
        SessionOwner,
        noteToken,
        yjsContent,
    } = props;

    const Note = Notes.findByToken(noteToken).state;

    const {
        name,
        revisionId,
        id,
    } = Note;

    const cover = getCoverJson({ NoteSettings, Covers, noteId: id });

    const icon = getIconJson({ NoteSettings, noteId: id });

    const publisher = getPublisherJson({ SessionOwner });

    const publishedAt = moment().toISOString();

    return {
        noteName: name,
        yjsContent,
        cover,
        icon,
        noteToken,
        publisher,
        publishedAt,
        revisionId,
    };
}
