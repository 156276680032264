/** EXTERNALS **/


import React from 'react';
import { Icon, View } from '@meisterlabs/knightrider';
import { colors } from '@meisterlabs/ui';


/** LOCALS **/


import NoteIconBackground from 'assets/icons/NoteIconBackground.svg';
import ICONS from 'src/config/icons';
import { noteIconSize } from 'config/sizes';


/** HELPERS **/


const getStyle = function({ hasNoteCover, noteIconColor }) {
    const topOffset = noteIconSize / -2;

    return {
        root: {
            height: noteIconSize,
            width: noteIconSize,
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'default',
            position: 'relative',
            marginTop: hasNoteCover ? topOffset : 0,

        },
        iconBackground: {
            position: 'absolute',
            top: 0,
            left: 0,
            size: '100%',
            color: noteIconColor,
        },
        icon: {
            color: colors.white,
            height: 48,
            with: 48,
        },
    };
};


/** MAIN **/


const Component = function(props) {
    const {
        noteIcon,
        hasNoteCover,
    } = props;

    const NoteIcon = ICONS.getComponent(noteIcon);
    const noteIconColor = ICONS.getColor(noteIcon);
    const style = getStyle({ hasNoteCover, noteIconColor });

    return (
        <View style={style.root}>
            <Icon style={style.iconBackground}>
                <NoteIconBackground />
            </Icon>
            <Icon style={style.icon}>
                <NoteIcon />
            </Icon>
        </View>
    );
};

export default Component;
