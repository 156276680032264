/** EXTERNALS **/


/** LOCALS **/


import ReactDOM from 'react-dom';


/** APP **/


class App {
    render({ render, target }) {
        return new Promise(function(resolve) {
            ReactDOM.render(render(), target, resolve);
        });
    }
}

export default new App();
