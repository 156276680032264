/** EXTERNALS **/


/** LOCALS **/


/** HELPERS **/


/** MAIN **/


const stack = [];

export const create = function(initialState) {
    stack.push(initialState);

    return initialState;
};

export const peek = function() {
    return stack[stack.length - 1] || {};
};

export const pop = function() {
    return stack.pop();
};
