/** EXTERNALS **/


/** LOCALS **/


/** HELPERS **/


/** MAIN **/


export default {
    editor: 0,
    noteCoverImage: 0,
    noteCoverChangeButton: 1,
    sidebar: 1,
    notebar: 1,
    noteHeader: 2,
    viewOnlyMessage: 12, // This needs to be higher than the navbar
    presentation: 13,
    loadingScreen: 14,
};
