/** EXTERNALS **/


/** LOCALS **/


/** HELPERS **/


/** MAIN **/


/*
    This is used to deduplicate messages, for example:

    StreamUtils
        .fromValue(1)
        .pipe(function(value, stream) {
            stream(value);
            stream(1);
            stream(1);
        })
        .pipe(function(value) {
            // This will run only once, rather than 3!
            console.log(value);
        });
*/
export default function(stream) {
    const uniq = function(v) {
        if (arguments.length === 0) return stream();
        if (stream() !== v) return stream(v);
        return stream;
    };

    Object.assign(uniq, stream);

    return uniq;
}
