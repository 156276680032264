/*
    TODO remove once kits PR is approved
*/
/** EXTERNALS **/


import pascalCase from 'pascalcase';


/** LOCALS **/


/** HELPERS **/


const isTextNode = node => node.nodeType === 3;


/** MAIN **/


const map = function(node, mappers, item) {
    return mappers[item.type]
        ? mappers[item.type](item, node)
        : item;
};

export default function(html, mappers = {}) {
    const doc = document.implementation.createHTMLDocument('foo');

    doc.documentElement.innerHTML = html;

    const node = doc.getElementsByTagName('body')[0];

    return Array
        .from(node.childNodes)
        .map(function(node) {
            if (isTextNode(node)) {
                return map(node, mappers, {
                    type: 'Text',
                    value: node.nodeValue,
                });
            }

            return map(node, mappers, {
                type: pascalCase(node.nodeName.toLowerCase()),
                value: node.innerHTML,
            });
        });
}
