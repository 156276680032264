/** EXTERNALS **/


/** LOCALS **/


/** HELPERS **/


/** MAIN **/


export const upperCase = function(string) {
    return string.toUpperCase();
};
