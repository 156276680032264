/** EXTERNALS **/


import kebabcase from 'lodash.kebabcase';


/** LOCALS **/


/** HELPERS **/


/** MAIN **/


export const kebabCase = function(string) {
    return kebabcase(string);
};
