
import {
    createMiddlewareEditor,
} from '@meisterlabs/editor';

import { withSerialization } from '../utils/withSerialization';

import { toBlocks } from 'src/utils/Slate/toBlocks';


export const useEditor = function({ content }) {
    const editor = createMiddlewareEditor(function() {
        withSerialization();
    });

    editor.children = toBlocks(content);

    return editor;
};
