/** EXTERNALS **/


import * as Context from '@meisterlabs/hooks-context';


/** LOCALS **/


/** HELPERS **/


/** MAIN **/


export const useRenderContext = function(componentFn) {
    return function(props) {
        Context.create({
            type: 'RenderContext',
        });

        const result = componentFn(props);

        Context.pop();

        return result;
    };
};
