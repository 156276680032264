/** EXTERNALS **/


import React from 'react';

import UrlPattern from 'url-pattern';


/** LOCALS **/


import ContextWrapper from './ContextWrapper';
import FullScreenSpinner from 'src/components/FullScreenSpinner';
import { useJsonPublic } from '../utils/hooks';
import { redirectTo404 } from '../utils/public';


/** HELPERS **/


const urlPattern = new UrlPattern(`/note/:noteToken(/)`);

const router = {
    parseRoute() {
        const path = window.location.pathname;
        const match = urlPattern.match(path);

        if (!match) {
            // TODO Error Report to Sentry
            // eslint-disable-next-line no-console
            console.error('Invalid Path', path);

            return {};
        }

        return match;
    },
};


/** MAIN **/


// To be used to render the public notes bundle
const PublicWrapper = function() {
    const { noteToken } = router.parseRoute();

    if (!noteToken && PRODUCTION) {
        redirectTo404();
    }

    const json = useJsonPublic({ noteToken });

    if (json === null) {
        return (
            <FullScreenSpinner />
        );
    }

    return (
        <ContextWrapper json={json} />
    );
};

export default PublicWrapper;
