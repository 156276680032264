/** EXTERNALS **/


import { parseI18nHtml } from '@meisterlabs/common';


/** LOCALS **/


/** HELPERS **/


import UNSPLASH from 'config/unsplash';
import i18n from 'utils/i18n';


/** MAIN **/


export default function(author, authorUrl, imageLink) {
    const html = i18n.t('cover.attribution');

    return parseI18nHtml(html, {
        Author() {
            return {
                type: 'Link',
                text: author,
                url: `${authorUrl}?${UNSPLASH.utm}`,
            };
        },
        Unsplash() {
            return {
                type: 'Link',
                text: 'Unsplash',
                url: `${imageLink}?${UNSPLASH.utm}`,
            };
        },
    });
}


