/** EXTERNALS **/


import { useEffect, useState } from 'react';


/** LOCALS **/


import { fetchNoteData } from './public';
import { useStore } from 'utils/Stream';
import generatePublicJson from './json';
import Editors from 'src/features/Editor/utils/Editors';


/** HELPERS **/


const getYjsContent = function({ id, Note }) {
    // Yjs content from the editor is prefered to the Note Store itself since the Note store is not updated over socket
    const editor = Editors.get(id);

    return editor?.getYjsContent() || Note.state.yjsContent;
};


/** MAIN **/


export const useJsonMeisterNote = function() {
    const CurrentRoute = useStore('CurrentRoute');
    const Notes = useStore('Notes');
    const NoteSettings = useStore('NoteSettings');
    const Covers = useStore('Covers');
    const SessionOwner = useStore('SessionOwner');

    const Note = CurrentRoute.Note().get();
    const id = CurrentRoute.noteId().get();
    const noteToken = CurrentRoute.noteToken().get();

    const [yjsContent, setYjsContent] = useState(() => getYjsContent({ id, Note }));

    // We need to subscribe to the editor to get the latest Yjs content from socket updates
    useEffect(() => {
        return Editors.subscribe(id, () => setYjsContent(getYjsContent({ id, Note })));
    }, [id]);

    return generatePublicJson({
        Notes,
        NoteSettings,
        Covers,
        SessionOwner,
        noteToken,
        yjsContent,
    });
};


export const useJsonPublic = function({ noteToken }) {
    const [json, setJson] = useState(null);

    useEffect(() => {
        fetchNoteData({ noteToken, callback: setJson });
    }, [noteToken]);

    return json;
};
